import React from 'react';

const CarouselDots = ({
  width = 367,
  height = 145,
  fill = '#A4B9A4',
  responsive = false,
  title = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };
  const labelId = title ? title.replace(/[^A-Z0-9]/gi, '_') : false;

  return (
    <svg
      viewBox="0 0 367 145"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      aria-labelledby={labelId ? labelId : undefined}
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      {labelId && <title id={labelId}>{title}</title>}
      <path
        d="M5.55814 2.77632C5.55814 4.30963 4.31391 5.55263 2.77907 5.55263C1.24423 5.55263 0 4.30963 0 2.77632C0 1.243 1.24423 0 2.77907 0C4.31391 0 5.55814 1.243 5.55814 2.77632Z"
        fill={fill}
      />
      <path
        d="M33.3488 2.77632C33.3488 4.30963 32.1046 5.55263 30.5698 5.55263C29.0349 5.55263 27.7907 4.30963 27.7907 2.77632C27.7907 1.243 29.0349 0 30.5698 0C32.1046 0 33.3488 1.243 33.3488 2.77632Z"
        fill={fill}
      />
      <path
        d="M61.1395 2.77632C61.1395 4.30963 59.8953 5.55263 58.3605 5.55263C56.8256 5.55263 55.5814 4.30963 55.5814 2.77632C55.5814 1.243 56.8256 0 58.3605 0C59.8953 0 61.1395 1.243 61.1395 2.77632Z"
        fill={fill}
      />
      <path
        d="M88.9302 2.77632C88.9302 4.30963 87.686 5.55263 86.1512 5.55263C84.6163 5.55263 83.3721 4.30963 83.3721 2.77632C83.3721 1.243 84.6163 0 86.1512 0C87.686 0 88.9302 1.243 88.9302 2.77632Z"
        fill={fill}
      />
      <path
        d="M116.721 2.77632C116.721 4.30963 115.477 5.55263 113.942 5.55263C112.407 5.55263 111.163 4.30963 111.163 2.77632C111.163 1.243 112.407 0 113.942 0C115.477 0 116.721 1.243 116.721 2.77632Z"
        fill={fill}
      />
      <path
        d="M144.512 2.77632C144.512 4.30963 143.267 5.55263 141.733 5.55263C140.198 5.55263 138.953 4.30963 138.953 2.77632C138.953 1.243 140.198 0 141.733 0C143.267 0 144.512 1.243 144.512 2.77632Z"
        fill={fill}
      />
      <path
        d="M172.302 2.77632C172.302 4.30963 171.058 5.55263 169.523 5.55263C167.988 5.55263 166.744 4.30963 166.744 2.77632C166.744 1.243 167.988 0 169.523 0C171.058 0 172.302 1.243 172.302 2.77632Z"
        fill={fill}
      />
      <path
        d="M200.093 2.77632C200.093 4.30963 198.849 5.55263 197.314 5.55263C195.779 5.55263 194.535 4.30963 194.535 2.77632C194.535 1.243 195.779 0 197.314 0C198.849 0 200.093 1.243 200.093 2.77632Z"
        fill={fill}
      />
      <path
        d="M227.884 2.77632C227.884 4.30963 226.639 5.55263 225.105 5.55263C223.57 5.55263 222.326 4.30963 222.326 2.77632C222.326 1.243 223.57 0 225.105 0C226.639 0 227.884 1.243 227.884 2.77632Z"
        fill={fill}
      />
      <path
        d="M255.674 2.77632C255.674 4.30963 254.43 5.55263 252.895 5.55263C251.361 5.55263 250.116 4.30963 250.116 2.77632C250.116 1.243 251.361 0 252.895 0C254.43 0 255.674 1.243 255.674 2.77632Z"
        fill={fill}
      />
      <path
        d="M283.465 2.77632C283.465 4.30963 282.221 5.55263 280.686 5.55263C279.151 5.55263 277.907 4.30963 277.907 2.77632C277.907 1.243 279.151 0 280.686 0C282.221 0 283.465 1.243 283.465 2.77632Z"
        fill={fill}
      />
      <path
        d="M311.256 2.77632C311.256 4.30963 310.012 5.55263 308.477 5.55263C306.942 5.55263 305.698 4.30963 305.698 2.77632C305.698 1.243 306.942 0 308.477 0C310.012 0 311.256 1.243 311.256 2.77632Z"
        fill={fill}
      />
      <path
        d="M339.047 2.77632C339.047 4.30963 337.802 5.55263 336.267 5.55263C334.733 5.55263 333.488 4.30963 333.488 2.77632C333.488 1.243 334.733 0 336.267 0C337.802 0 339.047 1.243 339.047 2.77632Z"
        fill={fill}
      />
      <path
        d="M366.837 2.77632C366.837 4.30963 365.593 5.55263 364.058 5.55263C362.523 5.55263 361.279 4.30963 361.279 2.77632C361.279 1.243 362.523 0 364.058 0C365.593 0 366.837 1.243 366.837 2.77632Z"
        fill={fill}
      />
      <path
        d="M5.55814 30.5395C5.55814 32.0728 4.31391 33.3158 2.77907 33.3158C1.24423 33.3158 0 32.0728 0 30.5395C0 29.0062 1.24423 27.7632 2.77907 27.7632C4.31391 27.7632 5.55814 29.0062 5.55814 30.5395Z"
        fill={fill}
      />
      <path
        d="M33.3488 30.5395C33.3488 32.0728 32.1046 33.3158 30.5698 33.3158C29.0349 33.3158 27.7907 32.0728 27.7907 30.5395C27.7907 29.0062 29.0349 27.7632 30.5698 27.7632C32.1046 27.7632 33.3488 29.0062 33.3488 30.5395Z"
        fill={fill}
      />
      <path
        d="M61.1395 30.5395C61.1395 32.0728 59.8953 33.3158 58.3605 33.3158C56.8256 33.3158 55.5814 32.0728 55.5814 30.5395C55.5814 29.0062 56.8256 27.7632 58.3605 27.7632C59.8953 27.7632 61.1395 29.0062 61.1395 30.5395Z"
        fill={fill}
      />
      <path
        d="M88.9302 30.5395C88.9302 32.0728 87.686 33.3158 86.1512 33.3158C84.6163 33.3158 83.3721 32.0728 83.3721 30.5395C83.3721 29.0062 84.6163 27.7632 86.1512 27.7632C87.686 27.7632 88.9302 29.0062 88.9302 30.5395Z"
        fill={fill}
      />
      <path
        d="M116.721 30.5395C116.721 32.0728 115.477 33.3158 113.942 33.3158C112.407 33.3158 111.163 32.0728 111.163 30.5395C111.163 29.0062 112.407 27.7632 113.942 27.7632C115.477 27.7632 116.721 29.0062 116.721 30.5395Z"
        fill={fill}
      />
      <path
        d="M144.512 30.5395C144.512 32.0728 143.267 33.3158 141.733 33.3158C140.198 33.3158 138.953 32.0728 138.953 30.5395C138.953 29.0062 140.198 27.7632 141.733 27.7632C143.267 27.7632 144.512 29.0062 144.512 30.5395Z"
        fill={fill}
      />
      <path
        d="M172.302 30.5395C172.302 32.0728 171.058 33.3158 169.523 33.3158C167.988 33.3158 166.744 32.0728 166.744 30.5395C166.744 29.0062 167.988 27.7632 169.523 27.7632C171.058 27.7632 172.302 29.0062 172.302 30.5395Z"
        fill={fill}
      />
      <path
        d="M200.093 30.5395C200.093 32.0728 198.849 33.3158 197.314 33.3158C195.779 33.3158 194.535 32.0728 194.535 30.5395C194.535 29.0062 195.779 27.7632 197.314 27.7632C198.849 27.7632 200.093 29.0062 200.093 30.5395Z"
        fill={fill}
      />
      <path
        d="M227.884 30.5395C227.884 32.0728 226.639 33.3158 225.105 33.3158C223.57 33.3158 222.326 32.0728 222.326 30.5395C222.326 29.0062 223.57 27.7632 225.105 27.7632C226.639 27.7632 227.884 29.0062 227.884 30.5395Z"
        fill={fill}
      />
      <path
        d="M255.674 30.5395C255.674 32.0728 254.43 33.3158 252.895 33.3158C251.361 33.3158 250.116 32.0728 250.116 30.5395C250.116 29.0062 251.361 27.7632 252.895 27.7632C254.43 27.7632 255.674 29.0062 255.674 30.5395Z"
        fill={fill}
      />
      <path
        d="M283.465 30.5395C283.465 32.0728 282.221 33.3158 280.686 33.3158C279.151 33.3158 277.907 32.0728 277.907 30.5395C277.907 29.0062 279.151 27.7632 280.686 27.7632C282.221 27.7632 283.465 29.0062 283.465 30.5395Z"
        fill={fill}
      />
      <path
        d="M311.256 30.5395C311.256 32.0728 310.012 33.3158 308.477 33.3158C306.942 33.3158 305.698 32.0728 305.698 30.5395C305.698 29.0062 306.942 27.7632 308.477 27.7632C310.012 27.7632 311.256 29.0062 311.256 30.5395Z"
        fill={fill}
      />
      <path
        d="M339.047 30.5395C339.047 32.0728 337.802 33.3158 336.267 33.3158C334.733 33.3158 333.488 32.0728 333.488 30.5395C333.488 29.0062 334.733 27.7632 336.267 27.7632C337.802 27.7632 339.047 29.0062 339.047 30.5395Z"
        fill={fill}
      />
      <path
        d="M366.837 30.5395C366.837 32.0728 365.593 33.3158 364.058 33.3158C362.523 33.3158 361.279 32.0728 361.279 30.5395C361.279 29.0062 362.523 27.7632 364.058 27.7632C365.593 27.7632 366.837 29.0062 366.837 30.5395Z"
        fill={fill}
      />
      <path
        d="M5.55814 58.3026C5.55814 59.8359 4.31391 61.0789 2.77907 61.0789C1.24423 61.0789 0 59.8359 0 58.3026C0 56.7693 1.24423 55.5263 2.77907 55.5263C4.31391 55.5263 5.55814 56.7693 5.55814 58.3026Z"
        fill={fill}
      />
      <path
        d="M33.3488 58.3026C33.3488 59.8359 32.1046 61.0789 30.5698 61.0789C29.0349 61.0789 27.7907 59.8359 27.7907 58.3026C27.7907 56.7693 29.0349 55.5263 30.5698 55.5263C32.1046 55.5263 33.3488 56.7693 33.3488 58.3026Z"
        fill={fill}
      />
      <path
        d="M61.1395 58.3026C61.1395 59.8359 59.8953 61.0789 58.3605 61.0789C56.8256 61.0789 55.5814 59.8359 55.5814 58.3026C55.5814 56.7693 56.8256 55.5263 58.3605 55.5263C59.8953 55.5263 61.1395 56.7693 61.1395 58.3026Z"
        fill={fill}
      />
      <path
        d="M88.9302 58.3026C88.9302 59.8359 87.686 61.0789 86.1512 61.0789C84.6163 61.0789 83.3721 59.8359 83.3721 58.3026C83.3721 56.7693 84.6163 55.5263 86.1512 55.5263C87.686 55.5263 88.9302 56.7693 88.9302 58.3026Z"
        fill={fill}
      />
      <path
        d="M116.721 58.3026C116.721 59.8359 115.477 61.0789 113.942 61.0789C112.407 61.0789 111.163 59.8359 111.163 58.3026C111.163 56.7693 112.407 55.5263 113.942 55.5263C115.477 55.5263 116.721 56.7693 116.721 58.3026Z"
        fill={fill}
      />
      <path
        d="M144.512 58.3026C144.512 59.8359 143.267 61.0789 141.733 61.0789C140.198 61.0789 138.953 59.8359 138.953 58.3026C138.953 56.7693 140.198 55.5263 141.733 55.5263C143.267 55.5263 144.512 56.7693 144.512 58.3026Z"
        fill={fill}
      />
      <path
        d="M172.302 58.3026C172.302 59.8359 171.058 61.0789 169.523 61.0789C167.988 61.0789 166.744 59.8359 166.744 58.3026C166.744 56.7693 167.988 55.5263 169.523 55.5263C171.058 55.5263 172.302 56.7693 172.302 58.3026Z"
        fill={fill}
      />
      <path
        d="M200.093 58.3026C200.093 59.8359 198.849 61.0789 197.314 61.0789C195.779 61.0789 194.535 59.8359 194.535 58.3026C194.535 56.7693 195.779 55.5263 197.314 55.5263C198.849 55.5263 200.093 56.7693 200.093 58.3026Z"
        fill={fill}
      />
      <path
        d="M227.884 58.3026C227.884 59.8359 226.639 61.0789 225.105 61.0789C223.57 61.0789 222.326 59.8359 222.326 58.3026C222.326 56.7693 223.57 55.5263 225.105 55.5263C226.639 55.5263 227.884 56.7693 227.884 58.3026Z"
        fill={fill}
      />
      <path
        d="M255.674 58.3026C255.674 59.8359 254.43 61.0789 252.895 61.0789C251.361 61.0789 250.116 59.8359 250.116 58.3026C250.116 56.7693 251.361 55.5263 252.895 55.5263C254.43 55.5263 255.674 56.7693 255.674 58.3026Z"
        fill={fill}
      />
      <path
        d="M283.465 58.3026C283.465 59.8359 282.221 61.0789 280.686 61.0789C279.151 61.0789 277.907 59.8359 277.907 58.3026C277.907 56.7693 279.151 55.5263 280.686 55.5263C282.221 55.5263 283.465 56.7693 283.465 58.3026Z"
        fill={fill}
      />
      <path
        d="M311.256 58.3026C311.256 59.8359 310.012 61.0789 308.477 61.0789C306.942 61.0789 305.698 59.8359 305.698 58.3026C305.698 56.7693 306.942 55.5263 308.477 55.5263C310.012 55.5263 311.256 56.7693 311.256 58.3026Z"
        fill={fill}
      />
      <path
        d="M339.047 58.3026C339.047 59.8359 337.802 61.0789 336.267 61.0789C334.733 61.0789 333.488 59.8359 333.488 58.3026C333.488 56.7693 334.733 55.5263 336.267 55.5263C337.802 55.5263 339.047 56.7693 339.047 58.3026Z"
        fill={fill}
      />
      <path
        d="M366.837 58.3026C366.837 59.8359 365.593 61.0789 364.058 61.0789C362.523 61.0789 361.279 59.8359 361.279 58.3026C361.279 56.7693 362.523 55.5263 364.058 55.5263C365.593 55.5263 366.837 56.7693 366.837 58.3026Z"
        fill={fill}
      />
      <path
        d="M5.55814 86.0658C5.55814 87.5991 4.31391 88.8421 2.77907 88.8421C1.24423 88.8421 0 87.5991 0 86.0658C0 84.5325 1.24423 83.2895 2.77907 83.2895C4.31391 83.2895 5.55814 84.5325 5.55814 86.0658Z"
        fill={fill}
      />
      <path
        d="M33.3488 86.0658C33.3488 87.5991 32.1046 88.8421 30.5698 88.8421C29.0349 88.8421 27.7907 87.5991 27.7907 86.0658C27.7907 84.5325 29.0349 83.2895 30.5698 83.2895C32.1046 83.2895 33.3488 84.5325 33.3488 86.0658Z"
        fill={fill}
      />
      <path
        d="M61.1395 86.0658C61.1395 87.5991 59.8953 88.8421 58.3605 88.8421C56.8256 88.8421 55.5814 87.5991 55.5814 86.0658C55.5814 84.5325 56.8256 83.2895 58.3605 83.2895C59.8953 83.2895 61.1395 84.5325 61.1395 86.0658Z"
        fill={fill}
      />
      <path
        d="M88.9302 86.0658C88.9302 87.5991 87.686 88.8421 86.1512 88.8421C84.6163 88.8421 83.3721 87.5991 83.3721 86.0658C83.3721 84.5325 84.6163 83.2895 86.1512 83.2895C87.686 83.2895 88.9302 84.5325 88.9302 86.0658Z"
        fill={fill}
      />
      <path
        d="M116.721 86.0658C116.721 87.5991 115.477 88.8421 113.942 88.8421C112.407 88.8421 111.163 87.5991 111.163 86.0658C111.163 84.5325 112.407 83.2895 113.942 83.2895C115.477 83.2895 116.721 84.5325 116.721 86.0658Z"
        fill={fill}
      />
      <path
        d="M144.512 86.0658C144.512 87.5991 143.267 88.8421 141.733 88.8421C140.198 88.8421 138.953 87.5991 138.953 86.0658C138.953 84.5325 140.198 83.2895 141.733 83.2895C143.267 83.2895 144.512 84.5325 144.512 86.0658Z"
        fill={fill}
      />
      <path
        d="M172.302 86.0658C172.302 87.5991 171.058 88.8421 169.523 88.8421C167.988 88.8421 166.744 87.5991 166.744 86.0658C166.744 84.5325 167.988 83.2895 169.523 83.2895C171.058 83.2895 172.302 84.5325 172.302 86.0658Z"
        fill={fill}
      />
      <path
        d="M200.093 86.0658C200.093 87.5991 198.849 88.8421 197.314 88.8421C195.779 88.8421 194.535 87.5991 194.535 86.0658C194.535 84.5325 195.779 83.2895 197.314 83.2895C198.849 83.2895 200.093 84.5325 200.093 86.0658Z"
        fill={fill}
      />
      <path
        d="M227.884 86.0658C227.884 87.5991 226.639 88.8421 225.105 88.8421C223.57 88.8421 222.326 87.5991 222.326 86.0658C222.326 84.5325 223.57 83.2895 225.105 83.2895C226.639 83.2895 227.884 84.5325 227.884 86.0658Z"
        fill={fill}
      />
      <path
        d="M255.674 86.0658C255.674 87.5991 254.43 88.8421 252.895 88.8421C251.361 88.8421 250.116 87.5991 250.116 86.0658C250.116 84.5325 251.361 83.2895 252.895 83.2895C254.43 83.2895 255.674 84.5325 255.674 86.0658Z"
        fill={fill}
      />
      <path
        d="M283.465 86.0658C283.465 87.5991 282.221 88.8421 280.686 88.8421C279.151 88.8421 277.907 87.5991 277.907 86.0658C277.907 84.5325 279.151 83.2895 280.686 83.2895C282.221 83.2895 283.465 84.5325 283.465 86.0658Z"
        fill={fill}
      />
      <path
        d="M311.256 86.0658C311.256 87.5991 310.012 88.8421 308.477 88.8421C306.942 88.8421 305.698 87.5991 305.698 86.0658C305.698 84.5325 306.942 83.2895 308.477 83.2895C310.012 83.2895 311.256 84.5325 311.256 86.0658Z"
        fill={fill}
      />
      <path
        d="M339.047 86.0658C339.047 87.5991 337.802 88.8421 336.267 88.8421C334.733 88.8421 333.488 87.5991 333.488 86.0658C333.488 84.5325 334.733 83.2895 336.267 83.2895C337.802 83.2895 339.047 84.5325 339.047 86.0658Z"
        fill={fill}
      />
      <path
        d="M366.837 86.0658C366.837 87.5991 365.593 88.8421 364.058 88.8421C362.523 88.8421 361.279 87.5991 361.279 86.0658C361.279 84.5325 362.523 83.2895 364.058 83.2895C365.593 83.2895 366.837 84.5325 366.837 86.0658Z"
        fill={fill}
      />
      <path
        d="M5.55814 113.829C5.55814 115.362 4.31391 116.605 2.77907 116.605C1.24423 116.605 0 115.362 0 113.829C0 112.296 1.24423 111.053 2.77907 111.053C4.31391 111.053 5.55814 112.296 5.55814 113.829Z"
        fill={fill}
      />
      <path
        d="M33.3488 113.829C33.3488 115.362 32.1046 116.605 30.5698 116.605C29.0349 116.605 27.7907 115.362 27.7907 113.829C27.7907 112.296 29.0349 111.053 30.5698 111.053C32.1046 111.053 33.3488 112.296 33.3488 113.829Z"
        fill={fill}
      />
      <path
        d="M61.1395 113.829C61.1395 115.362 59.8953 116.605 58.3605 116.605C56.8256 116.605 55.5814 115.362 55.5814 113.829C55.5814 112.296 56.8256 111.053 58.3605 111.053C59.8953 111.053 61.1395 112.296 61.1395 113.829Z"
        fill={fill}
      />
      <path
        d="M88.9302 113.829C88.9302 115.362 87.686 116.605 86.1512 116.605C84.6163 116.605 83.3721 115.362 83.3721 113.829C83.3721 112.296 84.6163 111.053 86.1512 111.053C87.686 111.053 88.9302 112.296 88.9302 113.829Z"
        fill={fill}
      />
      <path
        d="M116.721 113.829C116.721 115.362 115.477 116.605 113.942 116.605C112.407 116.605 111.163 115.362 111.163 113.829C111.163 112.296 112.407 111.053 113.942 111.053C115.477 111.053 116.721 112.296 116.721 113.829Z"
        fill={fill}
      />
      <path
        d="M144.512 113.829C144.512 115.362 143.267 116.605 141.733 116.605C140.198 116.605 138.953 115.362 138.953 113.829C138.953 112.296 140.198 111.053 141.733 111.053C143.267 111.053 144.512 112.296 144.512 113.829Z"
        fill={fill}
      />
      <path
        d="M172.302 113.829C172.302 115.362 171.058 116.605 169.523 116.605C167.988 116.605 166.744 115.362 166.744 113.829C166.744 112.296 167.988 111.053 169.523 111.053C171.058 111.053 172.302 112.296 172.302 113.829Z"
        fill={fill}
      />
      <path
        d="M200.093 113.829C200.093 115.362 198.849 116.605 197.314 116.605C195.779 116.605 194.535 115.362 194.535 113.829C194.535 112.296 195.779 111.053 197.314 111.053C198.849 111.053 200.093 112.296 200.093 113.829Z"
        fill={fill}
      />
      <path
        d="M227.884 113.829C227.884 115.362 226.639 116.605 225.105 116.605C223.57 116.605 222.326 115.362 222.326 113.829C222.326 112.296 223.57 111.053 225.105 111.053C226.639 111.053 227.884 112.296 227.884 113.829Z"
        fill={fill}
      />
      <path
        d="M255.674 113.829C255.674 115.362 254.43 116.605 252.895 116.605C251.361 116.605 250.116 115.362 250.116 113.829C250.116 112.296 251.361 111.053 252.895 111.053C254.43 111.053 255.674 112.296 255.674 113.829Z"
        fill={fill}
      />
      <path
        d="M283.465 113.829C283.465 115.362 282.221 116.605 280.686 116.605C279.151 116.605 277.907 115.362 277.907 113.829C277.907 112.296 279.151 111.053 280.686 111.053C282.221 111.053 283.465 112.296 283.465 113.829Z"
        fill={fill}
      />
      <path
        d="M311.256 113.829C311.256 115.362 310.012 116.605 308.477 116.605C306.942 116.605 305.698 115.362 305.698 113.829C305.698 112.296 306.942 111.053 308.477 111.053C310.012 111.053 311.256 112.296 311.256 113.829Z"
        fill={fill}
      />
      <path
        d="M339.047 113.829C339.047 115.362 337.802 116.605 336.267 116.605C334.733 116.605 333.488 115.362 333.488 113.829C333.488 112.296 334.733 111.053 336.267 111.053C337.802 111.053 339.047 112.296 339.047 113.829Z"
        fill={fill}
      />
      <path
        d="M366.837 113.829C366.837 115.362 365.593 116.605 364.058 116.605C362.523 116.605 361.279 115.362 361.279 113.829C361.279 112.296 362.523 111.053 364.058 111.053C365.593 111.053 366.837 112.296 366.837 113.829Z"
        fill={fill}
      />
      <path
        d="M5.55814 141.592C5.55814 143.125 4.31391 144.368 2.77907 144.368C1.24423 144.368 0 143.125 0 141.592C0 140.059 1.24423 138.816 2.77907 138.816C4.31391 138.816 5.55814 140.059 5.55814 141.592Z"
        fill={fill}
      />
      <path
        d="M33.3488 141.592C33.3488 143.125 32.1046 144.368 30.5698 144.368C29.0349 144.368 27.7907 143.125 27.7907 141.592C27.7907 140.059 29.0349 138.816 30.5698 138.816C32.1046 138.816 33.3488 140.059 33.3488 141.592Z"
        fill={fill}
      />
      <path
        d="M61.1395 141.592C61.1395 143.125 59.8953 144.368 58.3605 144.368C56.8256 144.368 55.5814 143.125 55.5814 141.592C55.5814 140.059 56.8256 138.816 58.3605 138.816C59.8953 138.816 61.1395 140.059 61.1395 141.592Z"
        fill={fill}
      />
      <path
        d="M88.9302 141.592C88.9302 143.125 87.686 144.368 86.1512 144.368C84.6163 144.368 83.3721 143.125 83.3721 141.592C83.3721 140.059 84.6163 138.816 86.1512 138.816C87.686 138.816 88.9302 140.059 88.9302 141.592Z"
        fill={fill}
      />
      <path
        d="M116.721 141.592C116.721 143.125 115.477 144.368 113.942 144.368C112.407 144.368 111.163 143.125 111.163 141.592C111.163 140.059 112.407 138.816 113.942 138.816C115.477 138.816 116.721 140.059 116.721 141.592Z"
        fill={fill}
      />
      <path
        d="M144.512 141.592C144.512 143.125 143.267 144.368 141.733 144.368C140.198 144.368 138.953 143.125 138.953 141.592C138.953 140.059 140.198 138.816 141.733 138.816C143.267 138.816 144.512 140.059 144.512 141.592Z"
        fill={fill}
      />
      <path
        d="M172.302 141.592C172.302 143.125 171.058 144.368 169.523 144.368C167.988 144.368 166.744 143.125 166.744 141.592C166.744 140.059 167.988 138.816 169.523 138.816C171.058 138.816 172.302 140.059 172.302 141.592Z"
        fill={fill}
      />
      <path
        d="M200.093 141.592C200.093 143.125 198.849 144.368 197.314 144.368C195.779 144.368 194.535 143.125 194.535 141.592C194.535 140.059 195.779 138.816 197.314 138.816C198.849 138.816 200.093 140.059 200.093 141.592Z"
        fill={fill}
      />
      <path
        d="M227.884 141.592C227.884 143.125 226.639 144.368 225.105 144.368C223.57 144.368 222.326 143.125 222.326 141.592C222.326 140.059 223.57 138.816 225.105 138.816C226.639 138.816 227.884 140.059 227.884 141.592Z"
        fill={fill}
      />
      <path
        d="M255.674 141.592C255.674 143.125 254.43 144.368 252.895 144.368C251.361 144.368 250.116 143.125 250.116 141.592C250.116 140.059 251.361 138.816 252.895 138.816C254.43 138.816 255.674 140.059 255.674 141.592Z"
        fill={fill}
      />
      <path
        d="M283.465 141.592C283.465 143.125 282.221 144.368 280.686 144.368C279.151 144.368 277.907 143.125 277.907 141.592C277.907 140.059 279.151 138.816 280.686 138.816C282.221 138.816 283.465 140.059 283.465 141.592Z"
        fill={fill}
      />
      <path
        d="M311.256 141.592C311.256 143.125 310.012 144.368 308.477 144.368C306.942 144.368 305.698 143.125 305.698 141.592C305.698 140.059 306.942 138.816 308.477 138.816C310.012 138.816 311.256 140.059 311.256 141.592Z"
        fill={fill}
      />
      <path
        d="M339.047 141.592C339.047 143.125 337.802 144.368 336.267 144.368C334.733 144.368 333.488 143.125 333.488 141.592C333.488 140.059 334.733 138.816 336.267 138.816C337.802 138.816 339.047 140.059 339.047 141.592Z"
        fill={fill}
      />
      <path
        d="M366.837 141.592C366.837 143.125 365.593 144.368 364.058 144.368C362.523 144.368 361.279 143.125 361.279 141.592C361.279 140.059 362.523 138.816 364.058 138.816C365.593 138.816 366.837 140.059 366.837 141.592Z"
        fill={fill}
      />
      <path
        d="M5.55814 169.355C5.55814 170.889 4.31391 172.132 2.77907 172.132C1.24423 172.132 0 170.889 0 169.355C0 167.822 1.24423 166.579 2.77907 166.579C4.31391 166.579 5.55814 167.822 5.55814 169.355Z"
        fill={fill}
      />
      <path
        d="M33.3488 169.355C33.3488 170.889 32.1046 172.132 30.5698 172.132C29.0349 172.132 27.7907 170.889 27.7907 169.355C27.7907 167.822 29.0349 166.579 30.5698 166.579C32.1046 166.579 33.3488 167.822 33.3488 169.355Z"
        fill={fill}
      />
      <path
        d="M61.1395 169.355C61.1395 170.889 59.8953 172.132 58.3605 172.132C56.8256 172.132 55.5814 170.889 55.5814 169.355C55.5814 167.822 56.8256 166.579 58.3605 166.579C59.8953 166.579 61.1395 167.822 61.1395 169.355Z"
        fill={fill}
      />
      <path
        d="M88.9302 169.355C88.9302 170.889 87.686 172.132 86.1512 172.132C84.6163 172.132 83.3721 170.889 83.3721 169.355C83.3721 167.822 84.6163 166.579 86.1512 166.579C87.686 166.579 88.9302 167.822 88.9302 169.355Z"
        fill={fill}
      />
      <path
        d="M116.721 169.355C116.721 170.889 115.477 172.132 113.942 172.132C112.407 172.132 111.163 170.889 111.163 169.355C111.163 167.822 112.407 166.579 113.942 166.579C115.477 166.579 116.721 167.822 116.721 169.355Z"
        fill={fill}
      />
      <path
        d="M144.512 169.355C144.512 170.889 143.267 172.132 141.733 172.132C140.198 172.132 138.953 170.889 138.953 169.355C138.953 167.822 140.198 166.579 141.733 166.579C143.267 166.579 144.512 167.822 144.512 169.355Z"
        fill={fill}
      />
      <path
        d="M172.302 169.355C172.302 170.889 171.058 172.132 169.523 172.132C167.988 172.132 166.744 170.889 166.744 169.355C166.744 167.822 167.988 166.579 169.523 166.579C171.058 166.579 172.302 167.822 172.302 169.355Z"
        fill={fill}
      />
      <path
        d="M200.093 169.355C200.093 170.889 198.849 172.132 197.314 172.132C195.779 172.132 194.535 170.889 194.535 169.355C194.535 167.822 195.779 166.579 197.314 166.579C198.849 166.579 200.093 167.822 200.093 169.355Z"
        fill={fill}
      />
      <path
        d="M227.884 169.355C227.884 170.889 226.639 172.132 225.105 172.132C223.57 172.132 222.326 170.889 222.326 169.355C222.326 167.822 223.57 166.579 225.105 166.579C226.639 166.579 227.884 167.822 227.884 169.355Z"
        fill={fill}
      />
      <path
        d="M255.674 169.355C255.674 170.889 254.43 172.132 252.895 172.132C251.361 172.132 250.116 170.889 250.116 169.355C250.116 167.822 251.361 166.579 252.895 166.579C254.43 166.579 255.674 167.822 255.674 169.355Z"
        fill={fill}
      />
      <path
        d="M283.465 169.355C283.465 170.889 282.221 172.132 280.686 172.132C279.151 172.132 277.907 170.889 277.907 169.355C277.907 167.822 279.151 166.579 280.686 166.579C282.221 166.579 283.465 167.822 283.465 169.355Z"
        fill={fill}
      />
      <path
        d="M311.256 169.355C311.256 170.889 310.012 172.132 308.477 172.132C306.942 172.132 305.698 170.889 305.698 169.355C305.698 167.822 306.942 166.579 308.477 166.579C310.012 166.579 311.256 167.822 311.256 169.355Z"
        fill={fill}
      />
      <path
        d="M339.047 169.355C339.047 170.889 337.802 172.132 336.267 172.132C334.733 172.132 333.488 170.889 333.488 169.355C333.488 167.822 334.733 166.579 336.267 166.579C337.802 166.579 339.047 167.822 339.047 169.355Z"
        fill={fill}
      />
      <path
        d="M366.837 169.355C366.837 170.889 365.593 172.132 364.058 172.132C362.523 172.132 361.279 170.889 361.279 169.355C361.279 167.822 362.523 166.579 364.058 166.579C365.593 166.579 366.837 167.822 366.837 169.355Z"
        fill={fill}
      />
      <path
        d="M5.55814 197.118C5.55814 198.652 4.31391 199.895 2.77907 199.895C1.24423 199.895 0 198.652 0 197.118C0 195.585 1.24423 194.342 2.77907 194.342C4.31391 194.342 5.55814 195.585 5.55814 197.118Z"
        fill={fill}
      />
      <path
        d="M33.3488 197.118C33.3488 198.652 32.1046 199.895 30.5698 199.895C29.0349 199.895 27.7907 198.652 27.7907 197.118C27.7907 195.585 29.0349 194.342 30.5698 194.342C32.1046 194.342 33.3488 195.585 33.3488 197.118Z"
        fill={fill}
      />
      <path
        d="M61.1395 197.118C61.1395 198.652 59.8953 199.895 58.3605 199.895C56.8256 199.895 55.5814 198.652 55.5814 197.118C55.5814 195.585 56.8256 194.342 58.3605 194.342C59.8953 194.342 61.1395 195.585 61.1395 197.118Z"
        fill={fill}
      />
      <path
        d="M88.9302 197.118C88.9302 198.652 87.686 199.895 86.1512 199.895C84.6163 199.895 83.3721 198.652 83.3721 197.118C83.3721 195.585 84.6163 194.342 86.1512 194.342C87.686 194.342 88.9302 195.585 88.9302 197.118Z"
        fill={fill}
      />
      <path
        d="M116.721 197.118C116.721 198.652 115.477 199.895 113.942 199.895C112.407 199.895 111.163 198.652 111.163 197.118C111.163 195.585 112.407 194.342 113.942 194.342C115.477 194.342 116.721 195.585 116.721 197.118Z"
        fill={fill}
      />
      <path
        d="M144.512 197.118C144.512 198.652 143.267 199.895 141.733 199.895C140.198 199.895 138.953 198.652 138.953 197.118C138.953 195.585 140.198 194.342 141.733 194.342C143.267 194.342 144.512 195.585 144.512 197.118Z"
        fill={fill}
      />
      <path
        d="M172.302 197.118C172.302 198.652 171.058 199.895 169.523 199.895C167.988 199.895 166.744 198.652 166.744 197.118C166.744 195.585 167.988 194.342 169.523 194.342C171.058 194.342 172.302 195.585 172.302 197.118Z"
        fill={fill}
      />
      <path
        d="M200.093 197.118C200.093 198.652 198.849 199.895 197.314 199.895C195.779 199.895 194.535 198.652 194.535 197.118C194.535 195.585 195.779 194.342 197.314 194.342C198.849 194.342 200.093 195.585 200.093 197.118Z"
        fill={fill}
      />
      <path
        d="M227.884 197.118C227.884 198.652 226.639 199.895 225.105 199.895C223.57 199.895 222.326 198.652 222.326 197.118C222.326 195.585 223.57 194.342 225.105 194.342C226.639 194.342 227.884 195.585 227.884 197.118Z"
        fill={fill}
      />
      <path
        d="M255.674 197.118C255.674 198.652 254.43 199.895 252.895 199.895C251.361 199.895 250.116 198.652 250.116 197.118C250.116 195.585 251.361 194.342 252.895 194.342C254.43 194.342 255.674 195.585 255.674 197.118Z"
        fill={fill}
      />
      <path
        d="M283.465 197.118C283.465 198.652 282.221 199.895 280.686 199.895C279.151 199.895 277.907 198.652 277.907 197.118C277.907 195.585 279.151 194.342 280.686 194.342C282.221 194.342 283.465 195.585 283.465 197.118Z"
        fill={fill}
      />
      <path
        d="M311.256 197.118C311.256 198.652 310.012 199.895 308.477 199.895C306.942 199.895 305.698 198.652 305.698 197.118C305.698 195.585 306.942 194.342 308.477 194.342C310.012 194.342 311.256 195.585 311.256 197.118Z"
        fill={fill}
      />
      <path
        d="M339.047 197.118C339.047 198.652 337.802 199.895 336.267 199.895C334.733 199.895 333.488 198.652 333.488 197.118C333.488 195.585 334.733 194.342 336.267 194.342C337.802 194.342 339.047 195.585 339.047 197.118Z"
        fill={fill}
      />
      <path
        d="M366.837 197.118C366.837 198.652 365.593 199.895 364.058 199.895C362.523 199.895 361.279 198.652 361.279 197.118C361.279 195.585 362.523 194.342 364.058 194.342C365.593 194.342 366.837 195.585 366.837 197.118Z"
        fill={fill}
      />
      <path
        d="M5.55814 224.882C5.55814 226.415 4.31391 227.658 2.77907 227.658C1.24423 227.658 0 226.415 0 224.882C0 223.348 1.24423 222.105 2.77907 222.105C4.31391 222.105 5.55814 223.348 5.55814 224.882Z"
        fill={fill}
      />
      <path
        d="M33.3488 224.882C33.3488 226.415 32.1046 227.658 30.5698 227.658C29.0349 227.658 27.7907 226.415 27.7907 224.882C27.7907 223.348 29.0349 222.105 30.5698 222.105C32.1046 222.105 33.3488 223.348 33.3488 224.882Z"
        fill={fill}
      />
      <path
        d="M61.1395 224.882C61.1395 226.415 59.8953 227.658 58.3605 227.658C56.8256 227.658 55.5814 226.415 55.5814 224.882C55.5814 223.348 56.8256 222.105 58.3605 222.105C59.8953 222.105 61.1395 223.348 61.1395 224.882Z"
        fill={fill}
      />
      <path
        d="M88.9302 224.882C88.9302 226.415 87.686 227.658 86.1512 227.658C84.6163 227.658 83.3721 226.415 83.3721 224.882C83.3721 223.348 84.6163 222.105 86.1512 222.105C87.686 222.105 88.9302 223.348 88.9302 224.882Z"
        fill={fill}
      />
      <path
        d="M116.721 224.882C116.721 226.415 115.477 227.658 113.942 227.658C112.407 227.658 111.163 226.415 111.163 224.882C111.163 223.348 112.407 222.105 113.942 222.105C115.477 222.105 116.721 223.348 116.721 224.882Z"
        fill={fill}
      />
      <path
        d="M144.512 224.882C144.512 226.415 143.267 227.658 141.733 227.658C140.198 227.658 138.953 226.415 138.953 224.882C138.953 223.348 140.198 222.105 141.733 222.105C143.267 222.105 144.512 223.348 144.512 224.882Z"
        fill={fill}
      />
      <path
        d="M172.302 224.882C172.302 226.415 171.058 227.658 169.523 227.658C167.988 227.658 166.744 226.415 166.744 224.882C166.744 223.348 167.988 222.105 169.523 222.105C171.058 222.105 172.302 223.348 172.302 224.882Z"
        fill={fill}
      />
      <path
        d="M200.093 224.882C200.093 226.415 198.849 227.658 197.314 227.658C195.779 227.658 194.535 226.415 194.535 224.882C194.535 223.348 195.779 222.105 197.314 222.105C198.849 222.105 200.093 223.348 200.093 224.882Z"
        fill={fill}
      />
      <path
        d="M227.884 224.882C227.884 226.415 226.639 227.658 225.105 227.658C223.57 227.658 222.326 226.415 222.326 224.882C222.326 223.348 223.57 222.105 225.105 222.105C226.639 222.105 227.884 223.348 227.884 224.882Z"
        fill={fill}
      />
      <path
        d="M255.674 224.882C255.674 226.415 254.43 227.658 252.895 227.658C251.361 227.658 250.116 226.415 250.116 224.882C250.116 223.348 251.361 222.105 252.895 222.105C254.43 222.105 255.674 223.348 255.674 224.882Z"
        fill={fill}
      />
      <path
        d="M283.465 224.882C283.465 226.415 282.221 227.658 280.686 227.658C279.151 227.658 277.907 226.415 277.907 224.882C277.907 223.348 279.151 222.105 280.686 222.105C282.221 222.105 283.465 223.348 283.465 224.882Z"
        fill={fill}
      />
      <path
        d="M311.256 224.882C311.256 226.415 310.012 227.658 308.477 227.658C306.942 227.658 305.698 226.415 305.698 224.882C305.698 223.348 306.942 222.105 308.477 222.105C310.012 222.105 311.256 223.348 311.256 224.882Z"
        fill={fill}
      />
      <path
        d="M339.047 224.882C339.047 226.415 337.802 227.658 336.267 227.658C334.733 227.658 333.488 226.415 333.488 224.882C333.488 223.348 334.733 222.105 336.267 222.105C337.802 222.105 339.047 223.348 339.047 224.882Z"
        fill={fill}
      />
      <path
        d="M366.837 224.882C366.837 226.415 365.593 227.658 364.058 227.658C362.523 227.658 361.279 226.415 361.279 224.882C361.279 223.348 362.523 222.105 364.058 222.105C365.593 222.105 366.837 223.348 366.837 224.882Z"
        fill={fill}
      />
      <path
        d="M5.55814 252.645C5.55814 254.178 4.31391 255.421 2.77907 255.421C1.24423 255.421 0 254.178 0 252.645C0 251.111 1.24423 249.868 2.77907 249.868C4.31391 249.868 5.55814 251.111 5.55814 252.645Z"
        fill={fill}
      />
      <path
        d="M33.3488 252.645C33.3488 254.178 32.1046 255.421 30.5698 255.421C29.0349 255.421 27.7907 254.178 27.7907 252.645C27.7907 251.111 29.0349 249.868 30.5698 249.868C32.1046 249.868 33.3488 251.111 33.3488 252.645Z"
        fill={fill}
      />
      <path
        d="M61.1395 252.645C61.1395 254.178 59.8953 255.421 58.3605 255.421C56.8256 255.421 55.5814 254.178 55.5814 252.645C55.5814 251.111 56.8256 249.868 58.3605 249.868C59.8953 249.868 61.1395 251.111 61.1395 252.645Z"
        fill={fill}
      />
      <path
        d="M88.9302 252.645C88.9302 254.178 87.686 255.421 86.1512 255.421C84.6163 255.421 83.3721 254.178 83.3721 252.645C83.3721 251.111 84.6163 249.868 86.1512 249.868C87.686 249.868 88.9302 251.111 88.9302 252.645Z"
        fill={fill}
      />
      <path
        d="M116.721 252.645C116.721 254.178 115.477 255.421 113.942 255.421C112.407 255.421 111.163 254.178 111.163 252.645C111.163 251.111 112.407 249.868 113.942 249.868C115.477 249.868 116.721 251.111 116.721 252.645Z"
        fill={fill}
      />
      <path
        d="M144.512 252.645C144.512 254.178 143.267 255.421 141.733 255.421C140.198 255.421 138.953 254.178 138.953 252.645C138.953 251.111 140.198 249.868 141.733 249.868C143.267 249.868 144.512 251.111 144.512 252.645Z"
        fill={fill}
      />
      <path
        d="M172.302 252.645C172.302 254.178 171.058 255.421 169.523 255.421C167.988 255.421 166.744 254.178 166.744 252.645C166.744 251.111 167.988 249.868 169.523 249.868C171.058 249.868 172.302 251.111 172.302 252.645Z"
        fill={fill}
      />
      <path
        d="M200.093 252.645C200.093 254.178 198.849 255.421 197.314 255.421C195.779 255.421 194.535 254.178 194.535 252.645C194.535 251.111 195.779 249.868 197.314 249.868C198.849 249.868 200.093 251.111 200.093 252.645Z"
        fill={fill}
      />
      <path
        d="M227.884 252.645C227.884 254.178 226.639 255.421 225.105 255.421C223.57 255.421 222.326 254.178 222.326 252.645C222.326 251.111 223.57 249.868 225.105 249.868C226.639 249.868 227.884 251.111 227.884 252.645Z"
        fill={fill}
      />
      <path
        d="M255.674 252.645C255.674 254.178 254.43 255.421 252.895 255.421C251.361 255.421 250.116 254.178 250.116 252.645C250.116 251.111 251.361 249.868 252.895 249.868C254.43 249.868 255.674 251.111 255.674 252.645Z"
        fill={fill}
      />
      <path
        d="M283.465 252.645C283.465 254.178 282.221 255.421 280.686 255.421C279.151 255.421 277.907 254.178 277.907 252.645C277.907 251.111 279.151 249.868 280.686 249.868C282.221 249.868 283.465 251.111 283.465 252.645Z"
        fill={fill}
      />
      <path
        d="M311.256 252.645C311.256 254.178 310.012 255.421 308.477 255.421C306.942 255.421 305.698 254.178 305.698 252.645C305.698 251.111 306.942 249.868 308.477 249.868C310.012 249.868 311.256 251.111 311.256 252.645Z"
        fill={fill}
      />
      <path
        d="M339.047 252.645C339.047 254.178 337.802 255.421 336.267 255.421C334.733 255.421 333.488 254.178 333.488 252.645C333.488 251.111 334.733 249.868 336.267 249.868C337.802 249.868 339.047 251.111 339.047 252.645Z"
        fill={fill}
      />
      <path
        d="M366.837 252.645C366.837 254.178 365.593 255.421 364.058 255.421C362.523 255.421 361.279 254.178 361.279 252.645C361.279 251.111 362.523 249.868 364.058 249.868C365.593 249.868 366.837 251.111 366.837 252.645Z"
        fill={fill}
      />
      <path
        d="M5.55814 280.408C5.55814 281.941 4.31391 283.184 2.77907 283.184C1.24423 283.184 0 281.941 0 280.408C0 278.875 1.24423 277.632 2.77907 277.632C4.31391 277.632 5.55814 278.875 5.55814 280.408Z"
        fill={fill}
      />
      <path
        d="M33.3488 280.408C33.3488 281.941 32.1046 283.184 30.5698 283.184C29.0349 283.184 27.7907 281.941 27.7907 280.408C27.7907 278.875 29.0349 277.632 30.5698 277.632C32.1046 277.632 33.3488 278.875 33.3488 280.408Z"
        fill={fill}
      />
      <path
        d="M61.1395 280.408C61.1395 281.941 59.8953 283.184 58.3605 283.184C56.8256 283.184 55.5814 281.941 55.5814 280.408C55.5814 278.875 56.8256 277.632 58.3605 277.632C59.8953 277.632 61.1395 278.875 61.1395 280.408Z"
        fill={fill}
      />
      <path
        d="M88.9302 280.408C88.9302 281.941 87.686 283.184 86.1512 283.184C84.6163 283.184 83.3721 281.941 83.3721 280.408C83.3721 278.875 84.6163 277.632 86.1512 277.632C87.686 277.632 88.9302 278.875 88.9302 280.408Z"
        fill={fill}
      />
      <path
        d="M116.721 280.408C116.721 281.941 115.477 283.184 113.942 283.184C112.407 283.184 111.163 281.941 111.163 280.408C111.163 278.875 112.407 277.632 113.942 277.632C115.477 277.632 116.721 278.875 116.721 280.408Z"
        fill={fill}
      />
      <path
        d="M144.512 280.408C144.512 281.941 143.267 283.184 141.733 283.184C140.198 283.184 138.953 281.941 138.953 280.408C138.953 278.875 140.198 277.632 141.733 277.632C143.267 277.632 144.512 278.875 144.512 280.408Z"
        fill={fill}
      />
      <path
        d="M172.302 280.408C172.302 281.941 171.058 283.184 169.523 283.184C167.988 283.184 166.744 281.941 166.744 280.408C166.744 278.875 167.988 277.632 169.523 277.632C171.058 277.632 172.302 278.875 172.302 280.408Z"
        fill={fill}
      />
      <path
        d="M200.093 280.408C200.093 281.941 198.849 283.184 197.314 283.184C195.779 283.184 194.535 281.941 194.535 280.408C194.535 278.875 195.779 277.632 197.314 277.632C198.849 277.632 200.093 278.875 200.093 280.408Z"
        fill={fill}
      />
      <path
        d="M227.884 280.408C227.884 281.941 226.639 283.184 225.105 283.184C223.57 283.184 222.326 281.941 222.326 280.408C222.326 278.875 223.57 277.632 225.105 277.632C226.639 277.632 227.884 278.875 227.884 280.408Z"
        fill={fill}
      />
      <path
        d="M255.674 280.408C255.674 281.941 254.43 283.184 252.895 283.184C251.361 283.184 250.116 281.941 250.116 280.408C250.116 278.875 251.361 277.632 252.895 277.632C254.43 277.632 255.674 278.875 255.674 280.408Z"
        fill={fill}
      />
      <path
        d="M283.465 280.408C283.465 281.941 282.221 283.184 280.686 283.184C279.151 283.184 277.907 281.941 277.907 280.408C277.907 278.875 279.151 277.632 280.686 277.632C282.221 277.632 283.465 278.875 283.465 280.408Z"
        fill={fill}
      />
      <path
        d="M311.256 280.408C311.256 281.941 310.012 283.184 308.477 283.184C306.942 283.184 305.698 281.941 305.698 280.408C305.698 278.875 306.942 277.632 308.477 277.632C310.012 277.632 311.256 278.875 311.256 280.408Z"
        fill={fill}
      />
      <path
        d="M339.047 280.408C339.047 281.941 337.802 283.184 336.267 283.184C334.733 283.184 333.488 281.941 333.488 280.408C333.488 278.875 334.733 277.632 336.267 277.632C337.802 277.632 339.047 278.875 339.047 280.408Z"
        fill={fill}
      />
      <path
        d="M366.837 280.408C366.837 281.941 365.593 283.184 364.058 283.184C362.523 283.184 361.279 281.941 361.279 280.408C361.279 278.875 362.523 277.632 364.058 277.632C365.593 277.632 366.837 278.875 366.837 280.408Z"
        fill={fill}
      />
      <path
        d="M5.55814 308.171C5.55814 309.704 4.31391 310.947 2.77907 310.947C1.24423 310.947 0 309.704 0 308.171C0 306.638 1.24423 305.395 2.77907 305.395C4.31391 305.395 5.55814 306.638 5.55814 308.171Z"
        fill={fill}
      />
      <path
        d="M33.3488 308.171C33.3488 309.704 32.1046 310.947 30.5698 310.947C29.0349 310.947 27.7907 309.704 27.7907 308.171C27.7907 306.638 29.0349 305.395 30.5698 305.395C32.1046 305.395 33.3488 306.638 33.3488 308.171Z"
        fill={fill}
      />
      <path
        d="M61.1395 308.171C61.1395 309.704 59.8953 310.947 58.3605 310.947C56.8256 310.947 55.5814 309.704 55.5814 308.171C55.5814 306.638 56.8256 305.395 58.3605 305.395C59.8953 305.395 61.1395 306.638 61.1395 308.171Z"
        fill={fill}
      />
      <path
        d="M88.9302 308.171C88.9302 309.704 87.686 310.947 86.1512 310.947C84.6163 310.947 83.3721 309.704 83.3721 308.171C83.3721 306.638 84.6163 305.395 86.1512 305.395C87.686 305.395 88.9302 306.638 88.9302 308.171Z"
        fill={fill}
      />
      <path
        d="M116.721 308.171C116.721 309.704 115.477 310.947 113.942 310.947C112.407 310.947 111.163 309.704 111.163 308.171C111.163 306.638 112.407 305.395 113.942 305.395C115.477 305.395 116.721 306.638 116.721 308.171Z"
        fill={fill}
      />
      <path
        d="M144.512 308.171C144.512 309.704 143.267 310.947 141.733 310.947C140.198 310.947 138.953 309.704 138.953 308.171C138.953 306.638 140.198 305.395 141.733 305.395C143.267 305.395 144.512 306.638 144.512 308.171Z"
        fill={fill}
      />
      <path
        d="M172.302 308.171C172.302 309.704 171.058 310.947 169.523 310.947C167.988 310.947 166.744 309.704 166.744 308.171C166.744 306.638 167.988 305.395 169.523 305.395C171.058 305.395 172.302 306.638 172.302 308.171Z"
        fill={fill}
      />
      <path
        d="M200.093 308.171C200.093 309.704 198.849 310.947 197.314 310.947C195.779 310.947 194.535 309.704 194.535 308.171C194.535 306.638 195.779 305.395 197.314 305.395C198.849 305.395 200.093 306.638 200.093 308.171Z"
        fill={fill}
      />
      <path
        d="M227.884 308.171C227.884 309.704 226.639 310.947 225.105 310.947C223.57 310.947 222.326 309.704 222.326 308.171C222.326 306.638 223.57 305.395 225.105 305.395C226.639 305.395 227.884 306.638 227.884 308.171Z"
        fill={fill}
      />
      <path
        d="M255.674 308.171C255.674 309.704 254.43 310.947 252.895 310.947C251.361 310.947 250.116 309.704 250.116 308.171C250.116 306.638 251.361 305.395 252.895 305.395C254.43 305.395 255.674 306.638 255.674 308.171Z"
        fill={fill}
      />
      <path
        d="M283.465 308.171C283.465 309.704 282.221 310.947 280.686 310.947C279.151 310.947 277.907 309.704 277.907 308.171C277.907 306.638 279.151 305.395 280.686 305.395C282.221 305.395 283.465 306.638 283.465 308.171Z"
        fill={fill}
      />
      <path
        d="M311.256 308.171C311.256 309.704 310.012 310.947 308.477 310.947C306.942 310.947 305.698 309.704 305.698 308.171C305.698 306.638 306.942 305.395 308.477 305.395C310.012 305.395 311.256 306.638 311.256 308.171Z"
        fill={fill}
      />
      <path
        d="M339.047 308.171C339.047 309.704 337.802 310.947 336.267 310.947C334.733 310.947 333.488 309.704 333.488 308.171C333.488 306.638 334.733 305.395 336.267 305.395C337.802 305.395 339.047 306.638 339.047 308.171Z"
        fill={fill}
      />
      <path
        d="M366.837 308.171C366.837 309.704 365.593 310.947 364.058 310.947C362.523 310.947 361.279 309.704 361.279 308.171C361.279 306.638 362.523 305.395 364.058 305.395C365.593 305.395 366.837 306.638 366.837 308.171Z"
        fill={fill}
      />
      <path
        d="M5.55814 335.934C5.55814 337.468 4.31391 338.711 2.77907 338.711C1.24423 338.711 0 337.468 0 335.934C0 334.401 1.24423 333.158 2.77907 333.158C4.31391 333.158 5.55814 334.401 5.55814 335.934Z"
        fill={fill}
      />
      <path
        d="M33.3488 335.934C33.3488 337.468 32.1046 338.711 30.5698 338.711C29.0349 338.711 27.7907 337.468 27.7907 335.934C27.7907 334.401 29.0349 333.158 30.5698 333.158C32.1046 333.158 33.3488 334.401 33.3488 335.934Z"
        fill={fill}
      />
      <path
        d="M61.1395 335.934C61.1395 337.468 59.8953 338.711 58.3605 338.711C56.8256 338.711 55.5814 337.468 55.5814 335.934C55.5814 334.401 56.8256 333.158 58.3605 333.158C59.8953 333.158 61.1395 334.401 61.1395 335.934Z"
        fill={fill}
      />
      <path
        d="M88.9302 335.934C88.9302 337.468 87.686 338.711 86.1512 338.711C84.6163 338.711 83.3721 337.468 83.3721 335.934C83.3721 334.401 84.6163 333.158 86.1512 333.158C87.686 333.158 88.9302 334.401 88.9302 335.934Z"
        fill={fill}
      />
      <path
        d="M116.721 335.934C116.721 337.468 115.477 338.711 113.942 338.711C112.407 338.711 111.163 337.468 111.163 335.934C111.163 334.401 112.407 333.158 113.942 333.158C115.477 333.158 116.721 334.401 116.721 335.934Z"
        fill={fill}
      />
      <path
        d="M144.512 335.934C144.512 337.468 143.267 338.711 141.733 338.711C140.198 338.711 138.953 337.468 138.953 335.934C138.953 334.401 140.198 333.158 141.733 333.158C143.267 333.158 144.512 334.401 144.512 335.934Z"
        fill={fill}
      />
      <path
        d="M172.302 335.934C172.302 337.468 171.058 338.711 169.523 338.711C167.988 338.711 166.744 337.468 166.744 335.934C166.744 334.401 167.988 333.158 169.523 333.158C171.058 333.158 172.302 334.401 172.302 335.934Z"
        fill={fill}
      />
      <path
        d="M200.093 335.934C200.093 337.468 198.849 338.711 197.314 338.711C195.779 338.711 194.535 337.468 194.535 335.934C194.535 334.401 195.779 333.158 197.314 333.158C198.849 333.158 200.093 334.401 200.093 335.934Z"
        fill={fill}
      />
      <path
        d="M227.884 335.934C227.884 337.468 226.639 338.711 225.105 338.711C223.57 338.711 222.326 337.468 222.326 335.934C222.326 334.401 223.57 333.158 225.105 333.158C226.639 333.158 227.884 334.401 227.884 335.934Z"
        fill={fill}
      />
      <path
        d="M255.674 335.934C255.674 337.468 254.43 338.711 252.895 338.711C251.361 338.711 250.116 337.468 250.116 335.934C250.116 334.401 251.361 333.158 252.895 333.158C254.43 333.158 255.674 334.401 255.674 335.934Z"
        fill={fill}
      />
      <path
        d="M283.465 335.934C283.465 337.468 282.221 338.711 280.686 338.711C279.151 338.711 277.907 337.468 277.907 335.934C277.907 334.401 279.151 333.158 280.686 333.158C282.221 333.158 283.465 334.401 283.465 335.934Z"
        fill={fill}
      />
      <path
        d="M311.256 335.934C311.256 337.468 310.012 338.711 308.477 338.711C306.942 338.711 305.698 337.468 305.698 335.934C305.698 334.401 306.942 333.158 308.477 333.158C310.012 333.158 311.256 334.401 311.256 335.934Z"
        fill={fill}
      />
      <path
        d="M339.047 335.934C339.047 337.468 337.802 338.711 336.267 338.711C334.733 338.711 333.488 337.468 333.488 335.934C333.488 334.401 334.733 333.158 336.267 333.158C337.802 333.158 339.047 334.401 339.047 335.934Z"
        fill={fill}
      />
      <path
        d="M366.837 335.934C366.837 337.468 365.593 338.711 364.058 338.711C362.523 338.711 361.279 337.468 361.279 335.934C361.279 334.401 362.523 333.158 364.058 333.158C365.593 333.158 366.837 334.401 366.837 335.934Z"
        fill={fill}
      />
      <path
        d="M5.55814 363.697C5.55814 365.231 4.31391 366.474 2.77907 366.474C1.24423 366.474 0 365.231 0 363.697C0 362.164 1.24423 360.921 2.77907 360.921C4.31391 360.921 5.55814 362.164 5.55814 363.697Z"
        fill={fill}
      />
      <path
        d="M33.3488 363.697C33.3488 365.231 32.1046 366.474 30.5698 366.474C29.0349 366.474 27.7907 365.231 27.7907 363.697C27.7907 362.164 29.0349 360.921 30.5698 360.921C32.1046 360.921 33.3488 362.164 33.3488 363.697Z"
        fill={fill}
      />
      <path
        d="M61.1395 363.697C61.1395 365.231 59.8953 366.474 58.3605 366.474C56.8256 366.474 55.5814 365.231 55.5814 363.697C55.5814 362.164 56.8256 360.921 58.3605 360.921C59.8953 360.921 61.1395 362.164 61.1395 363.697Z"
        fill={fill}
      />
      <path
        d="M88.9302 363.697C88.9302 365.231 87.686 366.474 86.1512 366.474C84.6163 366.474 83.3721 365.231 83.3721 363.697C83.3721 362.164 84.6163 360.921 86.1512 360.921C87.686 360.921 88.9302 362.164 88.9302 363.697Z"
        fill={fill}
      />
      <path
        d="M116.721 363.697C116.721 365.231 115.477 366.474 113.942 366.474C112.407 366.474 111.163 365.231 111.163 363.697C111.163 362.164 112.407 360.921 113.942 360.921C115.477 360.921 116.721 362.164 116.721 363.697Z"
        fill={fill}
      />
      <path
        d="M144.512 363.697C144.512 365.231 143.267 366.474 141.733 366.474C140.198 366.474 138.953 365.231 138.953 363.697C138.953 362.164 140.198 360.921 141.733 360.921C143.267 360.921 144.512 362.164 144.512 363.697Z"
        fill={fill}
      />
      <path
        d="M172.302 363.697C172.302 365.231 171.058 366.474 169.523 366.474C167.988 366.474 166.744 365.231 166.744 363.697C166.744 362.164 167.988 360.921 169.523 360.921C171.058 360.921 172.302 362.164 172.302 363.697Z"
        fill={fill}
      />
      <path
        d="M200.093 363.697C200.093 365.231 198.849 366.474 197.314 366.474C195.779 366.474 194.535 365.231 194.535 363.697C194.535 362.164 195.779 360.921 197.314 360.921C198.849 360.921 200.093 362.164 200.093 363.697Z"
        fill={fill}
      />
      <path
        d="M227.884 363.697C227.884 365.231 226.639 366.474 225.105 366.474C223.57 366.474 222.326 365.231 222.326 363.697C222.326 362.164 223.57 360.921 225.105 360.921C226.639 360.921 227.884 362.164 227.884 363.697Z"
        fill={fill}
      />
      <path
        d="M255.674 363.697C255.674 365.231 254.43 366.474 252.895 366.474C251.361 366.474 250.116 365.231 250.116 363.697C250.116 362.164 251.361 360.921 252.895 360.921C254.43 360.921 255.674 362.164 255.674 363.697Z"
        fill={fill}
      />
      <path
        d="M283.465 363.697C283.465 365.231 282.221 366.474 280.686 366.474C279.151 366.474 277.907 365.231 277.907 363.697C277.907 362.164 279.151 360.921 280.686 360.921C282.221 360.921 283.465 362.164 283.465 363.697Z"
        fill={fill}
      />
      <path
        d="M311.256 363.697C311.256 365.231 310.012 366.474 308.477 366.474C306.942 366.474 305.698 365.231 305.698 363.697C305.698 362.164 306.942 360.921 308.477 360.921C310.012 360.921 311.256 362.164 311.256 363.697Z"
        fill={fill}
      />
      <path
        d="M339.047 363.697C339.047 365.231 337.802 366.474 336.267 366.474C334.733 366.474 333.488 365.231 333.488 363.697C333.488 362.164 334.733 360.921 336.267 360.921C337.802 360.921 339.047 362.164 339.047 363.697Z"
        fill={fill}
      />
      <path
        d="M366.837 363.697C366.837 365.231 365.593 366.474 364.058 366.474C362.523 366.474 361.279 365.231 361.279 363.697C361.279 362.164 362.523 360.921 364.058 360.921C365.593 360.921 366.837 362.164 366.837 363.697Z"
        fill={fill}
      />
      <path
        d="M5.55814 391.461C5.55814 392.994 4.31391 394.237 2.77907 394.237C1.24423 394.237 0 392.994 0 391.461C0 389.927 1.24423 388.684 2.77907 388.684C4.31391 388.684 5.55814 389.927 5.55814 391.461Z"
        fill={fill}
      />
      <path
        d="M33.3488 391.461C33.3488 392.994 32.1046 394.237 30.5698 394.237C29.0349 394.237 27.7907 392.994 27.7907 391.461C27.7907 389.927 29.0349 388.684 30.5698 388.684C32.1046 388.684 33.3488 389.927 33.3488 391.461Z"
        fill={fill}
      />
      <path
        d="M61.1395 391.461C61.1395 392.994 59.8953 394.237 58.3605 394.237C56.8256 394.237 55.5814 392.994 55.5814 391.461C55.5814 389.927 56.8256 388.684 58.3605 388.684C59.8953 388.684 61.1395 389.927 61.1395 391.461Z"
        fill={fill}
      />
      <path
        d="M88.9302 391.461C88.9302 392.994 87.686 394.237 86.1512 394.237C84.6163 394.237 83.3721 392.994 83.3721 391.461C83.3721 389.927 84.6163 388.684 86.1512 388.684C87.686 388.684 88.9302 389.927 88.9302 391.461Z"
        fill={fill}
      />
      <path
        d="M116.721 391.461C116.721 392.994 115.477 394.237 113.942 394.237C112.407 394.237 111.163 392.994 111.163 391.461C111.163 389.927 112.407 388.684 113.942 388.684C115.477 388.684 116.721 389.927 116.721 391.461Z"
        fill={fill}
      />
      <path
        d="M144.512 391.461C144.512 392.994 143.267 394.237 141.733 394.237C140.198 394.237 138.953 392.994 138.953 391.461C138.953 389.927 140.198 388.684 141.733 388.684C143.267 388.684 144.512 389.927 144.512 391.461Z"
        fill={fill}
      />
      <path
        d="M172.302 391.461C172.302 392.994 171.058 394.237 169.523 394.237C167.988 394.237 166.744 392.994 166.744 391.461C166.744 389.927 167.988 388.684 169.523 388.684C171.058 388.684 172.302 389.927 172.302 391.461Z"
        fill={fill}
      />
      <path
        d="M200.093 391.461C200.093 392.994 198.849 394.237 197.314 394.237C195.779 394.237 194.535 392.994 194.535 391.461C194.535 389.927 195.779 388.684 197.314 388.684C198.849 388.684 200.093 389.927 200.093 391.461Z"
        fill={fill}
      />
      <path
        d="M227.884 391.461C227.884 392.994 226.639 394.237 225.105 394.237C223.57 394.237 222.326 392.994 222.326 391.461C222.326 389.927 223.57 388.684 225.105 388.684C226.639 388.684 227.884 389.927 227.884 391.461Z"
        fill={fill}
      />
      <path
        d="M255.674 391.461C255.674 392.994 254.43 394.237 252.895 394.237C251.361 394.237 250.116 392.994 250.116 391.461C250.116 389.927 251.361 388.684 252.895 388.684C254.43 388.684 255.674 389.927 255.674 391.461Z"
        fill={fill}
      />
      <path
        d="M283.465 391.461C283.465 392.994 282.221 394.237 280.686 394.237C279.151 394.237 277.907 392.994 277.907 391.461C277.907 389.927 279.151 388.684 280.686 388.684C282.221 388.684 283.465 389.927 283.465 391.461Z"
        fill={fill}
      />
      <path
        d="M311.256 391.461C311.256 392.994 310.012 394.237 308.477 394.237C306.942 394.237 305.698 392.994 305.698 391.461C305.698 389.927 306.942 388.684 308.477 388.684C310.012 388.684 311.256 389.927 311.256 391.461Z"
        fill={fill}
      />
      <path
        d="M339.047 391.461C339.047 392.994 337.802 394.237 336.267 394.237C334.733 394.237 333.488 392.994 333.488 391.461C333.488 389.927 334.733 388.684 336.267 388.684C337.802 388.684 339.047 389.927 339.047 391.461Z"
        fill={fill}
      />
      <path
        d="M366.837 391.461C366.837 392.994 365.593 394.237 364.058 394.237C362.523 394.237 361.279 392.994 361.279 391.461C361.279 389.927 362.523 388.684 364.058 388.684C365.593 388.684 366.837 389.927 366.837 391.461Z"
        fill={fill}
      />
      <path
        d="M5.55814 419.224C5.55814 420.757 4.31391 422 2.77907 422C1.24423 422 0 420.757 0 419.224C0 417.69 1.24423 416.447 2.77907 416.447C4.31391 416.447 5.55814 417.69 5.55814 419.224Z"
        fill={fill}
      />
      <path
        d="M33.3488 419.224C33.3488 420.757 32.1046 422 30.5698 422C29.0349 422 27.7907 420.757 27.7907 419.224C27.7907 417.69 29.0349 416.447 30.5698 416.447C32.1046 416.447 33.3488 417.69 33.3488 419.224Z"
        fill={fill}
      />
      <path
        d="M61.1395 419.224C61.1395 420.757 59.8953 422 58.3605 422C56.8256 422 55.5814 420.757 55.5814 419.224C55.5814 417.69 56.8256 416.447 58.3605 416.447C59.8953 416.447 61.1395 417.69 61.1395 419.224Z"
        fill={fill}
      />
      <path
        d="M88.9302 419.224C88.9302 420.757 87.686 422 86.1512 422C84.6163 422 83.3721 420.757 83.3721 419.224C83.3721 417.69 84.6163 416.447 86.1512 416.447C87.686 416.447 88.9302 417.69 88.9302 419.224Z"
        fill={fill}
      />
      <path
        d="M116.721 419.224C116.721 420.757 115.477 422 113.942 422C112.407 422 111.163 420.757 111.163 419.224C111.163 417.69 112.407 416.447 113.942 416.447C115.477 416.447 116.721 417.69 116.721 419.224Z"
        fill={fill}
      />
      <path
        d="M144.512 419.224C144.512 420.757 143.267 422 141.733 422C140.198 422 138.953 420.757 138.953 419.224C138.953 417.69 140.198 416.447 141.733 416.447C143.267 416.447 144.512 417.69 144.512 419.224Z"
        fill={fill}
      />
      <path
        d="M172.302 419.224C172.302 420.757 171.058 422 169.523 422C167.988 422 166.744 420.757 166.744 419.224C166.744 417.69 167.988 416.447 169.523 416.447C171.058 416.447 172.302 417.69 172.302 419.224Z"
        fill={fill}
      />
      <path
        d="M200.093 419.224C200.093 420.757 198.849 422 197.314 422C195.779 422 194.535 420.757 194.535 419.224C194.535 417.69 195.779 416.447 197.314 416.447C198.849 416.447 200.093 417.69 200.093 419.224Z"
        fill={fill}
      />
      <path
        d="M227.884 419.224C227.884 420.757 226.639 422 225.105 422C223.57 422 222.326 420.757 222.326 419.224C222.326 417.69 223.57 416.447 225.105 416.447C226.639 416.447 227.884 417.69 227.884 419.224Z"
        fill={fill}
      />
      <path
        d="M255.674 419.224C255.674 420.757 254.43 422 252.895 422C251.361 422 250.116 420.757 250.116 419.224C250.116 417.69 251.361 416.447 252.895 416.447C254.43 416.447 255.674 417.69 255.674 419.224Z"
        fill={fill}
      />
      <path
        d="M283.465 419.224C283.465 420.757 282.221 422 280.686 422C279.151 422 277.907 420.757 277.907 419.224C277.907 417.69 279.151 416.447 280.686 416.447C282.221 416.447 283.465 417.69 283.465 419.224Z"
        fill={fill}
      />
      <path
        d="M311.256 419.224C311.256 420.757 310.012 422 308.477 422C306.942 422 305.698 420.757 305.698 419.224C305.698 417.69 306.942 416.447 308.477 416.447C310.012 416.447 311.256 417.69 311.256 419.224Z"
        fill={fill}
      />
      <path
        d="M339.047 419.224C339.047 420.757 337.802 422 336.267 422C334.733 422 333.488 420.757 333.488 419.224C333.488 417.69 334.733 416.447 336.267 416.447C337.802 416.447 339.047 417.69 339.047 419.224Z"
        fill={fill}
      />
      <path
        d="M366.837 419.224C366.837 420.757 365.593 422 364.058 422C362.523 422 361.279 420.757 361.279 419.224C361.279 417.69 362.523 416.447 364.058 416.447C365.593 416.447 366.837 417.69 366.837 419.224Z"
        fill={fill}
      />
    </svg>
  );
};

export default CarouselDots;
