import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';
import { ButtonIconWrapper } from '../Button/index.style';

export const ScienceCarouselWrapper = styled.div`
  background-color: ${colors.pastelGreen400};
  min-height: 100vh;
  overflow: hidden;
  position: relative;

  ${mq.tabletL} {
    height: 100vh;
    min-height: 0;
    padding-top: 0;

    > div {
      height: 100%;
      > div {
        height: 100%;
        > div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }

  ${mq.desk} {
    height: auto;
    min-height: 100vh;
  }

  @media (min-width: 1240px) and (max-height: 700px) {
    height: auto;
    min-height: 120vh;
  }

  @media (min-width: 1600px) and (max-height: 1300px) {
    height: auto;
  }

  > div {
    min-height: 100vh;
    height: 100%;

    > div {
      min-height: 100vh;
      height: 100%;

      > div {
        min-height: 100vh;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  h2 {
    margin-bottom: 3rem;
    ${clamp('margin-top', 30, 100)}
    text-align: center;
  }
`;

export const ScienceCarouselNav = styled.ul`
  border-top: 0.1rem solid ${colors.humeBlack700};
  display: none;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  text-align: center;

  ${mq.tabletL} {
    bottom: 0;
    display: flex;
    margin-bottom: 0;
    margin-top: 4.2rem;
    padding-bottom: 2.1rem;
    padding-top: 2rem;
    position: relative;
    left: -2.2rem;
    right: -2.2rem;
    align-content: space-around;
    z-index: 4;
    width: calc(100% + 2 * 2.2rem);
  }

  ${mq.desk} {
    padding-left: 6.25%;
    padding-right: 6.25%;
  }

  ${mq.deskL} {
    padding-bottom: 6.3rem;
    padding-left: 12.5%;
    padding-right: 12.5%;
    padding-top: 6rem;
  }
`;

export const ScienceCarouselNavItem = styled.li`
  display: ${({ arrow }) => (arrow ? `none` : `inline-block`)};
  position: relative;
  text-align: center;
  white-space: nowrap;
  transition: opacity 0.3s ${easings.text.in};

  ${ButtonIconWrapper} {
    // margin-top: 0;
  }

  button {
    height: 5.2rem;
    width: ${({ arrow }) => (arrow ? `5.2rem` : `auto`)};
    opacity: ${({ current, index }) =>
      current !== index && index !== undefined ? 0.6 : 1};

    ${mq.tabletL} {
      margin-top: ${({ arrow }) => (arrow ? `0` : `.2rem`)};
    }

    &:after {
      background: ${colors.humeBlack700};
      border-radius: 50%;
      bottom: 0;
      content: '';
      opacity: ${({ current, index }) => (current !== index ? 0 : 1)};
      height: 0.6rem;
      left: 50%;
      position: absolute;
      transition: opacity 0.3s ${easings.text.in};
      transform: translateX(-50%);
      width: 0.6rem;
    }

    svg {
      font-size: 0;
    }
  }

  ${mq.tabletL} {
    display: ${({ arrow }) => (arrow ? `inline-block` : `inline-block`)};
    cursor: pointer;
    height: auto;
    margin-left: ${({ arrow }) => (arrow ? `auto` : `0`)};
    margin-right: ${({ arrow }) => (arrow ? `auto` : `0`)};
    width: auto;
  }

  ${({ inactive }) =>
    inactive && `opacity: .2; pointer-events: none; button{opacity:1;}`}

  > svg {
    display: inline-block;
    height: 0.2rem;
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    position: relative;
    top: -0.4rem;
    width: 6.2rem;

    ${mq.desk} {
      width: 8rem;
    }

    ${mq.deskL} {
      width: 9.2rem;
    }
  }

  ${({ navArrow }) => {
    if (navArrow) {
      return `
        button {
          border-radius: 50%;
          border: .1rem solid ${colors.humeBlack700};
          cursor: pointer;
          height: 5.8rem;
          width: 5.8rem;

          ${mq.deskL} {
            font-size: 0;
          }

          span {
            font-size: 0;
            margin-left: 0;
            margin-right: 0;
          }
        }
      `;
    }
  }}
`;

export const ScienceCarouselNavText = styled.span`
  display: inline-block;
  white-space: nowrap;
`;

export const ScienceCarouselItemWrap = styled.div`
  position: relative;
  width: 100%;

  > div {
    :not(:first-of-type) {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  ${mq.tabletL} {
  }
`;

export const ScienceCarouselImg = styled.img`
  border-radius: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  object-fit: cover;
  position: relative;
  height: 100%;
  width: 100%;

  ${mq.tabletL} {
    margin-top: 0;
  }
`;

export const ScienceCarouselMobileNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  left: 0;
  right: 0;
  margin-bottom: 2rem;
  margin-top: 4rem;

  ${mq.tabletL} {
    display: none;
  }
`;

export const ScienceCarouselCounter = styled.span`
  min-width: 10rem;
  text-align: center;
`;

export const ScienceCarouselCurrent = styled.span``;

export const ScienceCarouselMobileNavItem = styled.span`
  display: inline-block;

  ${({ inactive }) => (inactive ? `opacity: .2; pointer-events: none;` : ``)};

  ${({ navArrow }) =>
    navArrow === true
      ? `button {
    border-radius: 50%;
    border: .1rem solid ${colors.humeBlack700};
    cursor: pointer;
    height: 5.6rem;
    width: 5.6rem;

    span {
      margin-left: 0;
      margin-right: 0;
    }

    >span {
      margin-top: -.2rem;
    }
  }`
      : ``}
`;
