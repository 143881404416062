import React from 'react';

const CarouselDots = ({
  width = 367,
  height = 134,
  fill = '#B0C8D0',
  responsive = false,
  title = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };
  const labelId = title ? title.replace(/[^A-Z0-9]/gi, '_') : false;

  return (
    <svg
      viewBox="0 0 134 367"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      aria-labelledby={labelId ? labelId : undefined}
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      {labelId && <title id={labelId}>{title}</title>}

      <path
        d="M131.224 5.55814C129.69 5.55814 128.447 4.31391 128.447 2.77907C128.447 1.24423 129.69 -1.8838e-07 131.224 -1.21357e-07C132.757 -5.43332e-08 134 1.24423 134 2.77907C134 4.31391 132.757 5.55814 131.224 5.55814Z"
        fill={fill}
      />
      <path
        d="M131.224 33.3488C129.69 33.3488 128.447 32.1046 128.447 30.5698C128.447 29.0349 129.69 27.7907 131.224 27.7907C132.757 27.7907 134 29.0349 134 30.5698C134 32.1046 132.757 33.3488 131.224 33.3488Z"
        fill={fill}
      />
      <path
        d="M131.224 61.1395C129.69 61.1395 128.447 59.8953 128.447 58.3605C128.447 56.8256 129.69 55.5814 131.224 55.5814C132.757 55.5814 134 56.8256 134 58.3605C134 59.8953 132.757 61.1395 131.224 61.1395Z"
        fill={fill}
      />
      <path
        d="M131.224 88.9302C129.69 88.9302 128.447 87.686 128.447 86.1512C128.447 84.6163 129.69 83.3721 131.224 83.3721C132.757 83.3721 134 84.6163 134 86.1512C134 87.686 132.757 88.9302 131.224 88.9302Z"
        fill={fill}
      />
      <path
        d="M131.224 116.721C129.69 116.721 128.447 115.477 128.447 113.942C128.447 112.407 129.69 111.163 131.224 111.163C132.757 111.163 134 112.407 134 113.942C134 115.477 132.757 116.721 131.224 116.721Z"
        fill={fill}
      />
      <path
        d="M131.224 144.512C129.69 144.512 128.447 143.267 128.447 141.733C128.447 140.198 129.69 138.953 131.224 138.953C132.757 138.953 134 140.198 134 141.733C134 143.267 132.757 144.512 131.224 144.512Z"
        fill={fill}
      />
      <path
        d="M131.224 172.302C129.69 172.302 128.447 171.058 128.447 169.523C128.447 167.988 129.69 166.744 131.224 166.744C132.757 166.744 134 167.988 134 169.523C134 171.058 132.757 172.302 131.224 172.302Z"
        fill={fill}
      />
      <path
        d="M131.224 200.093C129.69 200.093 128.447 198.849 128.447 197.314C128.447 195.779 129.69 194.535 131.224 194.535C132.757 194.535 134 195.779 134 197.314C134 198.849 132.757 200.093 131.224 200.093Z"
        fill={fill}
      />
      <path
        d="M131.224 227.884C129.69 227.884 128.447 226.639 128.447 225.105C128.447 223.57 129.69 222.326 131.224 222.326C132.757 222.326 134 223.57 134 225.105C134 226.639 132.757 227.884 131.224 227.884Z"
        fill={fill}
      />
      <path
        d="M131.224 255.674C129.69 255.674 128.447 254.43 128.447 252.895C128.447 251.361 129.69 250.116 131.224 250.116C132.757 250.116 134 251.361 134 252.895C134 254.43 132.757 255.674 131.224 255.674Z"
        fill={fill}
      />
      <path
        d="M131.224 283.465C129.69 283.465 128.447 282.221 128.447 280.686C128.447 279.151 129.69 277.907 131.224 277.907C132.757 277.907 134 279.151 134 280.686C134 282.221 132.757 283.465 131.224 283.465Z"
        fill={fill}
      />
      <path
        d="M131.224 311.256C129.69 311.256 128.447 310.012 128.447 308.477C128.447 306.942 129.69 305.698 131.224 305.698C132.757 305.698 134 306.942 134 308.477C134 310.012 132.757 311.256 131.224 311.256Z"
        fill={fill}
      />
      <path
        d="M131.224 339.047C129.69 339.047 128.447 337.802 128.447 336.267C128.447 334.733 129.69 333.488 131.224 333.488C132.757 333.488 134 334.733 134 336.267C134 337.802 132.757 339.047 131.224 339.047Z"
        fill={fill}
      />
      <path
        d="M131.224 366.837C129.69 366.837 128.447 365.593 128.447 364.058C128.447 362.523 129.69 361.279 131.224 361.279C132.757 361.279 134 362.523 134 364.058C134 365.593 132.757 366.837 131.224 366.837Z"
        fill={fill}
      />
      <path
        d="M103.461 5.55814C101.927 5.55814 100.684 4.31391 100.684 2.77907C100.684 1.24423 101.927 -1.40195e-06 103.461 -1.33492e-06C104.994 -1.2679e-06 106.237 1.24423 106.237 2.77907C106.237 4.31391 104.994 5.55814 103.461 5.55814Z"
        fill={fill}
      />
      <path
        d="M103.461 33.3488C101.927 33.3488 100.684 32.1046 100.684 30.5698C100.684 29.0349 101.927 27.7907 103.461 27.7907C104.994 27.7907 106.237 29.0349 106.237 30.5698C106.237 32.1046 104.994 33.3488 103.461 33.3488Z"
        fill={fill}
      />
      <path
        d="M103.461 61.1395C101.927 61.1395 100.684 59.8953 100.684 58.3605C100.684 56.8256 101.927 55.5814 103.461 55.5814C104.994 55.5814 106.237 56.8256 106.237 58.3605C106.237 59.8953 104.994 61.1395 103.461 61.1395Z"
        fill={fill}
      />
      <path
        d="M103.461 88.9302C101.927 88.9302 100.684 87.686 100.684 86.1512C100.684 84.6163 101.927 83.3721 103.461 83.3721C104.994 83.3721 106.237 84.6163 106.237 86.1512C106.237 87.686 104.994 88.9302 103.461 88.9302Z"
        fill={fill}
      />
      <path
        d="M103.461 116.721C101.927 116.721 100.684 115.477 100.684 113.942C100.684 112.407 101.927 111.163 103.461 111.163C104.994 111.163 106.237 112.407 106.237 113.942C106.237 115.477 104.994 116.721 103.461 116.721Z"
        fill={fill}
      />
      <path
        d="M103.461 144.512C101.927 144.512 100.684 143.267 100.684 141.733C100.684 140.198 101.927 138.953 103.461 138.953C104.994 138.953 106.237 140.198 106.237 141.733C106.237 143.267 104.994 144.512 103.461 144.512Z"
        fill={fill}
      />
      <path
        d="M103.461 172.302C101.927 172.302 100.684 171.058 100.684 169.523C100.684 167.988 101.927 166.744 103.461 166.744C104.994 166.744 106.237 167.988 106.237 169.523C106.237 171.058 104.994 172.302 103.461 172.302Z"
        fill={fill}
      />
      <path
        d="M103.461 200.093C101.927 200.093 100.684 198.849 100.684 197.314C100.684 195.779 101.927 194.535 103.461 194.535C104.994 194.535 106.237 195.779 106.237 197.314C106.237 198.849 104.994 200.093 103.461 200.093Z"
        fill={fill}
      />
      <path
        d="M103.461 227.884C101.927 227.884 100.684 226.639 100.684 225.105C100.684 223.57 101.927 222.326 103.461 222.326C104.994 222.326 106.237 223.57 106.237 225.105C106.237 226.639 104.994 227.884 103.461 227.884Z"
        fill={fill}
      />
      <path
        d="M103.461 255.674C101.927 255.674 100.684 254.43 100.684 252.895C100.684 251.361 101.927 250.116 103.461 250.116C104.994 250.116 106.237 251.361 106.237 252.895C106.237 254.43 104.994 255.674 103.461 255.674Z"
        fill={fill}
      />
      <path
        d="M103.461 283.465C101.927 283.465 100.684 282.221 100.684 280.686C100.684 279.151 101.927 277.907 103.461 277.907C104.994 277.907 106.237 279.151 106.237 280.686C106.237 282.221 104.994 283.465 103.461 283.465Z"
        fill={fill}
      />
      <path
        d="M103.461 311.256C101.927 311.256 100.684 310.012 100.684 308.477C100.684 306.942 101.927 305.698 103.461 305.698C104.994 305.698 106.237 306.942 106.237 308.477C106.237 310.012 104.994 311.256 103.461 311.256Z"
        fill={fill}
      />
      <path
        d="M103.461 339.047C101.927 339.047 100.684 337.802 100.684 336.267C100.684 334.733 101.927 333.488 103.461 333.488C104.994 333.488 106.237 334.733 106.237 336.267C106.237 337.802 104.994 339.047 103.461 339.047Z"
        fill={fill}
      />
      <path
        d="M103.461 366.837C101.927 366.837 100.684 365.593 100.684 364.058C100.684 362.523 101.927 361.279 103.461 361.279C104.994 361.279 106.237 362.523 106.237 364.058C106.237 365.593 104.994 366.837 103.461 366.837Z"
        fill={fill}
      />
      <path
        d="M75.6974 5.55814C74.1641 5.55814 72.9211 4.3139 72.9211 2.77907C72.9211 1.24423 74.1641 -2.61551e-06 75.6974 -2.54849e-06C77.2307 -2.48147e-06 78.4737 1.24423 78.4737 2.77907C78.4737 4.31391 77.2307 5.55814 75.6974 5.55814Z"
        fill={fill}
      />
      <path
        d="M75.6974 33.3488C74.164 33.3488 72.921 32.1046 72.921 30.5698C72.921 29.0349 74.164 27.7907 75.6974 27.7907C77.2307 27.7907 78.4737 29.0349 78.4737 30.5698C78.4737 32.1046 77.2307 33.3488 75.6974 33.3488Z"
        fill={fill}
      />
      <path
        d="M75.6974 61.1395C74.164 61.1395 72.921 59.8953 72.921 58.3605C72.921 56.8256 74.164 55.5814 75.6974 55.5814C77.2307 55.5814 78.4737 56.8256 78.4737 58.3605C78.4737 59.8953 77.2307 61.1395 75.6974 61.1395Z"
        fill={fill}
      />
      <path
        d="M75.6974 88.9302C74.164 88.9302 72.921 87.686 72.921 86.1512C72.921 84.6163 74.164 83.3721 75.6974 83.3721C77.2307 83.3721 78.4737 84.6163 78.4737 86.1512C78.4737 87.686 77.2307 88.9302 75.6974 88.9302Z"
        fill={fill}
      />
      <path
        d="M75.6974 116.721C74.164 116.721 72.921 115.477 72.921 113.942C72.921 112.407 74.164 111.163 75.6974 111.163C77.2307 111.163 78.4737 112.407 78.4737 113.942C78.4737 115.477 77.2307 116.721 75.6974 116.721Z"
        fill={fill}
      />
      <path
        d="M75.6974 144.512C74.164 144.512 72.921 143.267 72.921 141.733C72.921 140.198 74.164 138.953 75.6974 138.953C77.2307 138.953 78.4737 140.198 78.4737 141.733C78.4737 143.267 77.2307 144.512 75.6974 144.512Z"
        fill={fill}
      />
      <path
        d="M75.6974 172.302C74.164 172.302 72.921 171.058 72.921 169.523C72.921 167.988 74.164 166.744 75.6974 166.744C77.2307 166.744 78.4737 167.988 78.4737 169.523C78.4737 171.058 77.2307 172.302 75.6974 172.302Z"
        fill={fill}
      />
      <path
        d="M75.6974 200.093C74.164 200.093 72.921 198.849 72.921 197.314C72.921 195.779 74.164 194.535 75.6974 194.535C77.2307 194.535 78.4737 195.779 78.4737 197.314C78.4737 198.849 77.2307 200.093 75.6974 200.093Z"
        fill={fill}
      />
      <path
        d="M75.6974 227.884C74.164 227.884 72.921 226.639 72.921 225.105C72.921 223.57 74.164 222.326 75.6974 222.326C77.2307 222.326 78.4737 223.57 78.4737 225.105C78.4737 226.639 77.2307 227.884 75.6974 227.884Z"
        fill={fill}
      />
      <path
        d="M75.6974 255.674C74.164 255.674 72.921 254.43 72.921 252.895C72.921 251.361 74.164 250.116 75.6974 250.116C77.2307 250.116 78.4737 251.361 78.4737 252.895C78.4737 254.43 77.2307 255.674 75.6974 255.674Z"
        fill={fill}
      />
      <path
        d="M75.6974 283.465C74.164 283.465 72.921 282.221 72.921 280.686C72.921 279.151 74.164 277.907 75.6974 277.907C77.2307 277.907 78.4737 279.151 78.4737 280.686C78.4737 282.221 77.2307 283.465 75.6974 283.465Z"
        fill={fill}
      />
      <path
        d="M75.6974 311.256C74.164 311.256 72.921 310.012 72.921 308.477C72.921 306.942 74.164 305.698 75.6974 305.698C77.2307 305.698 78.4737 306.942 78.4737 308.477C78.4737 310.012 77.2307 311.256 75.6974 311.256Z"
        fill={fill}
      />
      <path
        d="M75.6974 339.047C74.164 339.047 72.921 337.802 72.921 336.267C72.921 334.733 74.164 333.488 75.6974 333.488C77.2307 333.488 78.4737 334.733 78.4737 336.267C78.4737 337.802 77.2307 339.047 75.6974 339.047Z"
        fill={fill}
      />
      <path
        d="M75.6974 366.837C74.164 366.837 72.921 365.593 72.921 364.058C72.921 362.523 74.164 361.279 75.6974 361.279C77.2307 361.279 78.4737 362.523 78.4737 364.058C78.4737 365.593 77.2307 366.837 75.6974 366.837Z"
        fill={fill}
      />
      <path
        d="M47.9342 5.55814C46.4009 5.55814 45.1579 4.3139 45.1579 2.77907C45.1579 1.24423 46.4009 -3.82908e-06 47.9342 -3.76206e-06C49.4675 -3.69503e-06 50.7105 1.24423 50.7105 2.77907C50.7105 4.3139 49.4675 5.55814 47.9342 5.55814Z"
        fill={fill}
      />
      <path
        d="M47.9342 33.3488C46.4009 33.3488 45.1579 32.1046 45.1579 30.5698C45.1579 29.0349 46.4009 27.7907 47.9342 27.7907C49.4675 27.7907 50.7105 29.0349 50.7105 30.5698C50.7105 32.1046 49.4675 33.3488 47.9342 33.3488Z"
        fill={fill}
      />
      <path
        d="M47.9342 61.1395C46.4009 61.1395 45.1579 59.8953 45.1579 58.3605C45.1579 56.8256 46.4009 55.5814 47.9342 55.5814C49.4675 55.5814 50.7105 56.8256 50.7105 58.3605C50.7105 59.8953 49.4675 61.1395 47.9342 61.1395Z"
        fill={fill}
      />
      <path
        d="M47.9342 88.9302C46.4009 88.9302 45.1579 87.686 45.1579 86.1512C45.1579 84.6163 46.4009 83.3721 47.9342 83.3721C49.4675 83.3721 50.7105 84.6163 50.7105 86.1512C50.7105 87.686 49.4675 88.9302 47.9342 88.9302Z"
        fill={fill}
      />
      <path
        d="M47.9342 116.721C46.4009 116.721 45.1579 115.477 45.1579 113.942C45.1579 112.407 46.4009 111.163 47.9342 111.163C49.4675 111.163 50.7105 112.407 50.7105 113.942C50.7105 115.477 49.4675 116.721 47.9342 116.721Z"
        fill={fill}
      />
      <path
        d="M47.9342 144.512C46.4009 144.512 45.1579 143.267 45.1579 141.733C45.1579 140.198 46.4009 138.953 47.9342 138.953C49.4675 138.953 50.7105 140.198 50.7105 141.733C50.7105 143.267 49.4675 144.512 47.9342 144.512Z"
        fill={fill}
      />
      <path
        d="M47.9342 172.302C46.4009 172.302 45.1579 171.058 45.1579 169.523C45.1579 167.988 46.4009 166.744 47.9342 166.744C49.4675 166.744 50.7105 167.988 50.7105 169.523C50.7105 171.058 49.4675 172.302 47.9342 172.302Z"
        fill={fill}
      />
      <path
        d="M47.9342 200.093C46.4009 200.093 45.1579 198.849 45.1579 197.314C45.1579 195.779 46.4009 194.535 47.9342 194.535C49.4675 194.535 50.7105 195.779 50.7105 197.314C50.7105 198.849 49.4675 200.093 47.9342 200.093Z"
        fill={fill}
      />
      <path
        d="M47.9342 227.884C46.4009 227.884 45.1579 226.639 45.1579 225.105C45.1579 223.57 46.4009 222.326 47.9342 222.326C49.4675 222.326 50.7105 223.57 50.7105 225.105C50.7105 226.639 49.4675 227.884 47.9342 227.884Z"
        fill={fill}
      />
      <path
        d="M47.9342 255.674C46.4009 255.674 45.1579 254.43 45.1579 252.895C45.1579 251.361 46.4009 250.116 47.9342 250.116C49.4675 250.116 50.7105 251.361 50.7105 252.895C50.7105 254.43 49.4675 255.674 47.9342 255.674Z"
        fill={fill}
      />
      <path
        d="M47.9342 283.465C46.4009 283.465 45.1579 282.221 45.1579 280.686C45.1579 279.151 46.4009 277.907 47.9342 277.907C49.4675 277.907 50.7105 279.151 50.7105 280.686C50.7105 282.221 49.4675 283.465 47.9342 283.465Z"
        fill={fill}
      />
      <path
        d="M47.9342 311.256C46.4009 311.256 45.1579 310.012 45.1579 308.477C45.1579 306.942 46.4009 305.698 47.9342 305.698C49.4675 305.698 50.7105 306.942 50.7105 308.477C50.7105 310.012 49.4675 311.256 47.9342 311.256Z"
        fill={fill}
      />
      <path
        d="M47.9342 339.047C46.4009 339.047 45.1579 337.802 45.1579 336.267C45.1579 334.733 46.4009 333.488 47.9342 333.488C49.4675 333.488 50.7105 334.733 50.7105 336.267C50.7105 337.802 49.4675 339.047 47.9342 339.047Z"
        fill={fill}
      />
      <path
        d="M47.9342 366.837C46.4009 366.837 45.1579 365.593 45.1579 364.058C45.1579 362.523 46.4009 361.279 47.9342 361.279C49.4675 361.279 50.7105 362.523 50.7105 364.058C50.7105 365.593 49.4675 366.837 47.9342 366.837Z"
        fill={fill}
      />
      <path
        d="M20.1711 5.55813C18.6377 5.55813 17.3947 4.3139 17.3947 2.77906C17.3947 1.24423 18.6377 -5.04264e-06 20.1711 -4.97562e-06C21.7044 -4.9086e-06 22.9474 1.24423 22.9474 2.77907C22.9474 4.3139 21.7044 5.55813 20.1711 5.55813Z"
        fill={fill}
      />
      <path
        d="M20.171 33.3488C18.6377 33.3488 17.3947 32.1046 17.3947 30.5698C17.3947 29.0349 18.6377 27.7907 20.171 27.7907C21.7044 27.7907 22.9474 29.0349 22.9474 30.5698C22.9474 32.1046 21.7044 33.3488 20.171 33.3488Z"
        fill={fill}
      />
      <path
        d="M20.171 61.1395C18.6377 61.1395 17.3947 59.8953 17.3947 58.3605C17.3947 56.8256 18.6377 55.5814 20.171 55.5814C21.7044 55.5814 22.9474 56.8256 22.9474 58.3605C22.9474 59.8953 21.7044 61.1395 20.171 61.1395Z"
        fill={fill}
      />
      <path
        d="M20.171 88.9302C18.6377 88.9302 17.3947 87.686 17.3947 86.1512C17.3947 84.6163 18.6377 83.3721 20.171 83.3721C21.7044 83.3721 22.9474 84.6163 22.9474 86.1512C22.9474 87.686 21.7044 88.9302 20.171 88.9302Z"
        fill={fill}
      />
      <path
        d="M20.171 116.721C18.6377 116.721 17.3947 115.477 17.3947 113.942C17.3947 112.407 18.6377 111.163 20.171 111.163C21.7044 111.163 22.9474 112.407 22.9474 113.942C22.9474 115.477 21.7044 116.721 20.171 116.721Z"
        fill={fill}
      />
      <path
        d="M20.171 144.512C18.6377 144.512 17.3947 143.267 17.3947 141.733C17.3947 140.198 18.6377 138.953 20.171 138.953C21.7044 138.953 22.9474 140.198 22.9474 141.733C22.9474 143.267 21.7044 144.512 20.171 144.512Z"
        fill={fill}
      />
      <path
        d="M20.171 172.302C18.6377 172.302 17.3947 171.058 17.3947 169.523C17.3947 167.988 18.6377 166.744 20.171 166.744C21.7044 166.744 22.9474 167.988 22.9474 169.523C22.9474 171.058 21.7044 172.302 20.171 172.302Z"
        fill={fill}
      />
      <path
        d="M20.171 200.093C18.6377 200.093 17.3947 198.849 17.3947 197.314C17.3947 195.779 18.6377 194.535 20.171 194.535C21.7044 194.535 22.9474 195.779 22.9474 197.314C22.9474 198.849 21.7044 200.093 20.171 200.093Z"
        fill={fill}
      />
      <path
        d="M20.171 227.884C18.6377 227.884 17.3947 226.639 17.3947 225.105C17.3947 223.57 18.6377 222.326 20.171 222.326C21.7044 222.326 22.9474 223.57 22.9474 225.105C22.9474 226.639 21.7044 227.884 20.171 227.884Z"
        fill={fill}
      />
      <path
        d="M20.171 255.674C18.6377 255.674 17.3947 254.43 17.3947 252.895C17.3947 251.361 18.6377 250.116 20.171 250.116C21.7044 250.116 22.9474 251.361 22.9474 252.895C22.9474 254.43 21.7044 255.674 20.171 255.674Z"
        fill={fill}
      />
      <path
        d="M20.171 283.465C18.6377 283.465 17.3947 282.221 17.3947 280.686C17.3947 279.151 18.6377 277.907 20.171 277.907C21.7044 277.907 22.9474 279.151 22.9474 280.686C22.9474 282.221 21.7044 283.465 20.171 283.465Z"
        fill={fill}
      />
      <path
        d="M20.171 311.256C18.6377 311.256 17.3947 310.012 17.3947 308.477C17.3947 306.942 18.6377 305.698 20.171 305.698C21.7044 305.698 22.9474 306.942 22.9474 308.477C22.9474 310.012 21.7044 311.256 20.171 311.256Z"
        fill={fill}
      />
      <path
        d="M20.171 339.047C18.6377 339.047 17.3947 337.802 17.3947 336.267C17.3947 334.733 18.6377 333.488 20.171 333.488C21.7044 333.488 22.9474 334.733 22.9474 336.267C22.9474 337.802 21.7044 339.047 20.171 339.047Z"
        fill={fill}
      />
      <path
        d="M20.171 366.837C18.6377 366.837 17.3947 365.593 17.3947 364.058C17.3947 362.523 18.6377 361.279 20.171 361.279C21.7044 361.279 22.9473 362.523 22.9473 364.058C22.9473 365.593 21.7044 366.837 20.171 366.837Z"
        fill={fill}
      />
      <path
        d="M-7.5921 5.55813C-9.12541 5.55813 -10.3684 4.3139 -10.3684 2.77906C-10.3684 1.24423 -9.12541 -6.25621e-06 -7.5921 -6.18919e-06C-6.05879 -6.12216e-06 -4.8158 1.24423 -4.8158 2.77906C-4.8158 4.3139 -6.05879 5.55813 -7.5921 5.55813Z"
        fill={fill}
      />
      <path
        d="M-7.5921 33.3488C-9.12541 33.3488 -10.3684 32.1046 -10.3684 30.5698C-10.3684 29.0349 -9.12541 27.7907 -7.5921 27.7907C-6.05879 27.7907 -4.8158 29.0349 -4.8158 30.5698C-4.8158 32.1046 -6.05879 33.3488 -7.5921 33.3488Z"
        fill={fill}
      />
      <path
        d="M-7.5921 61.1395C-9.12541 61.1395 -10.3684 59.8953 -10.3684 58.3605C-10.3684 56.8256 -9.12541 55.5814 -7.5921 55.5814C-6.05879 55.5814 -4.8158 56.8256 -4.8158 58.3605C-4.8158 59.8953 -6.05879 61.1395 -7.5921 61.1395Z"
        fill={fill}
      />
      <path
        d="M-7.59211 88.9302C-9.12542 88.9302 -10.3684 87.686 -10.3684 86.1512C-10.3684 84.6163 -9.12542 83.3721 -7.59211 83.3721C-6.0588 83.3721 -4.8158 84.6163 -4.8158 86.1512C-4.8158 87.686 -6.0588 88.9302 -7.59211 88.9302Z"
        fill={fill}
      />
      <path
        d="M-7.59211 116.721C-9.12542 116.721 -10.3684 115.477 -10.3684 113.942C-10.3684 112.407 -9.12542 111.163 -7.59211 111.163C-6.0588 111.163 -4.8158 112.407 -4.8158 113.942C-4.8158 115.477 -6.0588 116.721 -7.59211 116.721Z"
        fill={fill}
      />
      <path
        d="M-7.59211 144.512C-9.12542 144.512 -10.3684 143.267 -10.3684 141.733C-10.3684 140.198 -9.12542 138.953 -7.59211 138.953C-6.0588 138.953 -4.8158 140.198 -4.8158 141.733C-4.8158 143.267 -6.0588 144.512 -7.59211 144.512Z"
        fill={fill}
      />
      <path
        d="M-7.59211 172.302C-9.12542 172.302 -10.3684 171.058 -10.3684 169.523C-10.3684 167.988 -9.12542 166.744 -7.59211 166.744C-6.0588 166.744 -4.8158 167.988 -4.8158 169.523C-4.8158 171.058 -6.0588 172.302 -7.59211 172.302Z"
        fill={fill}
      />
      <path
        d="M-7.59211 200.093C-9.12542 200.093 -10.3684 198.849 -10.3684 197.314C-10.3684 195.779 -9.12542 194.535 -7.59211 194.535C-6.0588 194.535 -4.8158 195.779 -4.8158 197.314C-4.8158 198.849 -6.0588 200.093 -7.59211 200.093Z"
        fill={fill}
      />
      <path
        d="M-7.59211 227.884C-9.12542 227.884 -10.3684 226.639 -10.3684 225.105C-10.3684 223.57 -9.12542 222.326 -7.59211 222.326C-6.0588 222.326 -4.81581 223.57 -4.81581 225.105C-4.81581 226.639 -6.0588 227.884 -7.59211 227.884Z"
        fill={fill}
      />
      <path
        d="M-7.59211 255.674C-9.12542 255.674 -10.3684 254.43 -10.3684 252.895C-10.3684 251.361 -9.12542 250.116 -7.59211 250.116C-6.0588 250.116 -4.81581 251.361 -4.81581 252.895C-4.81581 254.43 -6.0588 255.674 -7.59211 255.674Z"
        fill={fill}
      />
      <path
        d="M-7.59211 283.465C-9.12542 283.465 -10.3684 282.221 -10.3684 280.686C-10.3684 279.151 -9.12542 277.907 -7.59211 277.907C-6.0588 277.907 -4.81581 279.151 -4.81581 280.686C-4.81581 282.221 -6.0588 283.465 -7.59211 283.465Z"
        fill={fill}
      />
      <path
        d="M-7.59212 311.256C-9.12543 311.256 -10.3684 310.012 -10.3684 308.477C-10.3684 306.942 -9.12543 305.698 -7.59212 305.698C-6.05881 305.698 -4.81581 306.942 -4.81581 308.477C-4.81581 310.012 -6.05881 311.256 -7.59212 311.256Z"
        fill={fill}
      />
      <path
        d="M-7.59212 339.047C-9.12543 339.047 -10.3684 337.802 -10.3684 336.267C-10.3684 334.733 -9.12543 333.488 -7.59212 333.488C-6.05881 333.488 -4.81581 334.733 -4.81581 336.267C-4.81581 337.802 -6.05881 339.047 -7.59212 339.047Z"
        fill={fill}
      />
      <path
        d="M-7.59212 366.837C-9.12543 366.837 -10.3684 365.593 -10.3684 364.058C-10.3684 362.523 -9.12543 361.279 -7.59212 361.279C-6.05881 361.279 -4.81581 362.523 -4.81581 364.058C-4.81581 365.593 -6.05881 366.837 -7.59212 366.837Z"
        fill={fill}
      />
      <path
        d="M-35.3553 5.55813C-36.8886 5.55813 -38.1316 4.3139 -38.1316 2.77906C-38.1316 1.24422 -36.8886 -7.46978e-06 -35.3553 -7.40275e-06C-33.822 -7.33573e-06 -32.5789 1.24422 -32.5789 2.77906C-32.5789 4.3139 -33.822 5.55813 -35.3553 5.55813Z"
        fill={fill}
      />
      <path
        d="M-35.3553 33.3488C-36.8886 33.3488 -38.1316 32.1046 -38.1316 30.5698C-38.1316 29.0349 -36.8886 27.7907 -35.3553 27.7907C-33.822 27.7907 -32.579 29.0349 -32.579 30.5698C-32.579 32.1046 -33.822 33.3488 -35.3553 33.3488Z"
        fill={fill}
      />
      <path
        d="M-35.3553 61.1395C-36.8886 61.1395 -38.1316 59.8953 -38.1316 58.3605C-38.1316 56.8256 -36.8886 55.5814 -35.3553 55.5814C-33.822 55.5814 -32.579 56.8256 -32.579 58.3605C-32.579 59.8953 -33.822 61.1395 -35.3553 61.1395Z"
        fill={fill}
      />
      <path
        d="M-35.3553 88.9302C-36.8886 88.9302 -38.1316 87.686 -38.1316 86.1512C-38.1316 84.6163 -36.8886 83.3721 -35.3553 83.3721C-33.822 83.3721 -32.579 84.6163 -32.579 86.1512C-32.579 87.686 -33.822 88.9302 -35.3553 88.9302Z"
        fill={fill}
      />
      <path
        d="M-35.3553 116.721C-36.8886 116.721 -38.1316 115.477 -38.1316 113.942C-38.1316 112.407 -36.8886 111.163 -35.3553 111.163C-33.822 111.163 -32.579 112.407 -32.579 113.942C-32.579 115.477 -33.822 116.721 -35.3553 116.721Z"
        fill={fill}
      />
      <path
        d="M-35.3553 144.512C-36.8886 144.512 -38.1316 143.267 -38.1316 141.733C-38.1316 140.198 -36.8886 138.953 -35.3553 138.953C-33.822 138.953 -32.579 140.198 -32.579 141.733C-32.579 143.267 -33.822 144.512 -35.3553 144.512Z"
        fill={fill}
      />
      <path
        d="M-35.3553 172.302C-36.8886 172.302 -38.1316 171.058 -38.1316 169.523C-38.1316 167.988 -36.8886 166.744 -35.3553 166.744C-33.822 166.744 -32.579 167.988 -32.579 169.523C-32.579 171.058 -33.822 172.302 -35.3553 172.302Z"
        fill={fill}
      />
      <path
        d="M-35.3553 200.093C-36.8886 200.093 -38.1316 198.849 -38.1316 197.314C-38.1316 195.779 -36.8886 194.535 -35.3553 194.535C-33.822 194.535 -32.579 195.779 -32.579 197.314C-32.579 198.849 -33.822 200.093 -35.3553 200.093Z"
        fill={fill}
      />
      <path
        d="M-35.3553 227.884C-36.8886 227.884 -38.1316 226.639 -38.1316 225.105C-38.1316 223.57 -36.8886 222.326 -35.3553 222.326C-33.822 222.326 -32.579 223.57 -32.579 225.105C-32.579 226.639 -33.822 227.884 -35.3553 227.884Z"
        fill={fill}
      />
      <path
        d="M-35.3553 255.674C-36.8886 255.674 -38.1316 254.43 -38.1316 252.895C-38.1316 251.361 -36.8886 250.116 -35.3553 250.116C-33.822 250.116 -32.579 251.361 -32.579 252.895C-32.579 254.43 -33.822 255.674 -35.3553 255.674Z"
        fill={fill}
      />
      <path
        d="M-35.3553 283.465C-36.8886 283.465 -38.1316 282.221 -38.1316 280.686C-38.1316 279.151 -36.8886 277.907 -35.3553 277.907C-33.822 277.907 -32.579 279.151 -32.579 280.686C-32.579 282.221 -33.822 283.465 -35.3553 283.465Z"
        fill={fill}
      />
      <path
        d="M-35.3553 311.256C-36.8886 311.256 -38.1316 310.012 -38.1316 308.477C-38.1316 306.942 -36.8886 305.698 -35.3553 305.698C-33.822 305.698 -32.579 306.942 -32.579 308.477C-32.579 310.012 -33.822 311.256 -35.3553 311.256Z"
        fill={fill}
      />
      <path
        d="M-35.3553 339.047C-36.8886 339.047 -38.1316 337.802 -38.1316 336.267C-38.1316 334.733 -36.8886 333.488 -35.3553 333.488C-33.822 333.488 -32.579 334.733 -32.579 336.267C-32.579 337.802 -33.822 339.047 -35.3553 339.047Z"
        fill={fill}
      />
      <path
        d="M-35.3553 366.837C-36.8886 366.837 -38.1316 365.593 -38.1316 364.058C-38.1316 362.523 -36.8886 361.279 -35.3553 361.279C-33.822 361.279 -32.579 362.523 -32.579 364.058C-32.579 365.593 -33.822 366.837 -35.3553 366.837Z"
        fill={fill}
      />
      <path
        d="M-63.1184 5.55813C-64.6517 5.55813 -65.8947 4.3139 -65.8947 2.77906C-65.8947 1.24422 -64.6517 -8.68334e-06 -63.1184 -8.61632e-06C-61.5851 -8.5493e-06 -60.3421 1.24422 -60.3421 2.77906C-60.3421 4.3139 -61.5851 5.55813 -63.1184 5.55813Z"
        fill={fill}
      />
      <path
        d="M-63.1184 33.3488C-64.6517 33.3488 -65.8947 32.1046 -65.8947 30.5698C-65.8947 29.0349 -64.6517 27.7907 -63.1184 27.7907C-61.5851 27.7907 -60.3421 29.0349 -60.3421 30.5698C-60.3421 32.1046 -61.5851 33.3488 -63.1184 33.3488Z"
        fill={fill}
      />
      <path
        d="M-63.1184 61.1395C-64.6517 61.1395 -65.8947 59.8953 -65.8947 58.3605C-65.8947 56.8256 -64.6517 55.5814 -63.1184 55.5814C-61.5851 55.5814 -60.3421 56.8256 -60.3421 58.3605C-60.3421 59.8953 -61.5851 61.1395 -63.1184 61.1395Z"
        fill={fill}
      />
      <path
        d="M-63.1184 88.9302C-64.6517 88.9302 -65.8947 87.686 -65.8947 86.1512C-65.8947 84.6163 -64.6517 83.3721 -63.1184 83.3721C-61.5851 83.3721 -60.3421 84.6163 -60.3421 86.1512C-60.3421 87.686 -61.5851 88.9302 -63.1184 88.9302Z"
        fill={fill}
      />
      <path
        d="M-63.1184 116.721C-64.6517 116.721 -65.8947 115.477 -65.8947 113.942C-65.8947 112.407 -64.6517 111.163 -63.1184 111.163C-61.5851 111.163 -60.3421 112.407 -60.3421 113.942C-60.3421 115.477 -61.5851 116.721 -63.1184 116.721Z"
        fill={fill}
      />
      <path
        d="M-63.1184 144.512C-64.6517 144.512 -65.8948 143.267 -65.8948 141.733C-65.8948 140.198 -64.6517 138.953 -63.1184 138.953C-61.5851 138.953 -60.3421 140.198 -60.3421 141.733C-60.3421 143.267 -61.5851 144.512 -63.1184 144.512Z"
        fill={fill}
      />
      <path
        d="M-63.1184 172.302C-64.6517 172.302 -65.8948 171.058 -65.8948 169.523C-65.8948 167.988 -64.6517 166.744 -63.1184 166.744C-61.5851 166.744 -60.3421 167.988 -60.3421 169.523C-60.3421 171.058 -61.5851 172.302 -63.1184 172.302Z"
        fill={fill}
      />
      <path
        d="M-63.1184 200.093C-64.6517 200.093 -65.8948 198.849 -65.8948 197.314C-65.8948 195.779 -64.6517 194.535 -63.1184 194.535C-61.5851 194.535 -60.3421 195.779 -60.3421 197.314C-60.3421 198.849 -61.5851 200.093 -63.1184 200.093Z"
        fill={fill}
      />
      <path
        d="M-63.1184 227.884C-64.6517 227.884 -65.8948 226.639 -65.8948 225.105C-65.8948 223.57 -64.6517 222.326 -63.1184 222.326C-61.5851 222.326 -60.3421 223.57 -60.3421 225.105C-60.3421 226.639 -61.5851 227.884 -63.1184 227.884Z"
        fill={fill}
      />
      <path
        d="M-63.1184 255.674C-64.6517 255.674 -65.8948 254.43 -65.8948 252.895C-65.8948 251.361 -64.6517 250.116 -63.1184 250.116C-61.5851 250.116 -60.3421 251.361 -60.3421 252.895C-60.3421 254.43 -61.5851 255.674 -63.1184 255.674Z"
        fill={fill}
      />
      <path
        d="M-63.1184 283.465C-64.6517 283.465 -65.8948 282.221 -65.8948 280.686C-65.8948 279.151 -64.6517 277.907 -63.1184 277.907C-61.5851 277.907 -60.3421 279.151 -60.3421 280.686C-60.3421 282.221 -61.5851 283.465 -63.1184 283.465Z"
        fill={fill}
      />
      <path
        d="M-63.1184 311.256C-64.6517 311.256 -65.8948 310.012 -65.8948 308.477C-65.8948 306.942 -64.6517 305.698 -63.1184 305.698C-61.5851 305.698 -60.3421 306.942 -60.3421 308.477C-60.3421 310.012 -61.5851 311.256 -63.1184 311.256Z"
        fill={fill}
      />
      <path
        d="M-63.1184 339.047C-64.6517 339.047 -65.8948 337.802 -65.8948 336.267C-65.8948 334.733 -64.6517 333.488 -63.1184 333.488C-61.5851 333.488 -60.3421 334.733 -60.3421 336.267C-60.3421 337.802 -61.5851 339.047 -63.1184 339.047Z"
        fill={fill}
      />
      <path
        d="M-63.1184 366.837C-64.6517 366.837 -65.8948 365.593 -65.8948 364.058C-65.8948 362.523 -64.6517 361.279 -63.1184 361.279C-61.5851 361.279 -60.3421 362.523 -60.3421 364.058C-60.3421 365.593 -61.5851 366.837 -63.1184 366.837Z"
        fill={fill}
      />
      <path
        d="M-90.8816 5.55813C-92.4149 5.55813 -93.6579 4.3139 -93.6579 2.77906C-93.6579 1.24422 -92.4149 -9.89691e-06 -90.8816 -9.82989e-06C-89.3483 -9.76286e-06 -88.1053 1.24422 -88.1053 2.77906C-88.1053 4.3139 -89.3483 5.55813 -90.8816 5.55813Z"
        fill={fill}
      />
      <path
        d="M-90.8816 33.3488C-92.4149 33.3488 -93.6579 32.1046 -93.6579 30.5698C-93.6579 29.0349 -92.4149 27.7907 -90.8816 27.7907C-89.3483 27.7907 -88.1053 29.0349 -88.1053 30.5698C-88.1053 32.1046 -89.3483 33.3488 -90.8816 33.3488Z"
        fill={fill}
      />
      <path
        d="M-90.8816 61.1395C-92.4149 61.1395 -93.6579 59.8953 -93.6579 58.3605C-93.6579 56.8256 -92.4149 55.5814 -90.8816 55.5814C-89.3483 55.5814 -88.1053 56.8256 -88.1053 58.3605C-88.1053 59.8953 -89.3483 61.1395 -90.8816 61.1395Z"
        fill={fill}
      />
      <path
        d="M-90.8816 88.9302C-92.4149 88.9302 -93.6579 87.686 -93.6579 86.1512C-93.6579 84.6163 -92.4149 83.3721 -90.8816 83.3721C-89.3483 83.3721 -88.1053 84.6163 -88.1053 86.1512C-88.1053 87.686 -89.3483 88.9302 -90.8816 88.9302Z"
        fill={fill}
      />
      <path
        d="M-90.8816 116.721C-92.4149 116.721 -93.6579 115.477 -93.6579 113.942C-93.6579 112.407 -92.4149 111.163 -90.8816 111.163C-89.3483 111.163 -88.1053 112.407 -88.1053 113.942C-88.1053 115.477 -89.3483 116.721 -90.8816 116.721Z"
        fill={fill}
      />
      <path
        d="M-90.8816 144.512C-92.4149 144.512 -93.6579 143.267 -93.6579 141.733C-93.6579 140.198 -92.4149 138.953 -90.8816 138.953C-89.3483 138.953 -88.1053 140.198 -88.1053 141.733C-88.1053 143.267 -89.3483 144.512 -90.8816 144.512Z"
        fill={fill}
      />
      <path
        d="M-90.8816 172.302C-92.4149 172.302 -93.6579 171.058 -93.6579 169.523C-93.6579 167.988 -92.4149 166.744 -90.8816 166.744C-89.3483 166.744 -88.1053 167.988 -88.1053 169.523C-88.1053 171.058 -89.3483 172.302 -90.8816 172.302Z"
        fill={fill}
      />
      <path
        d="M-90.8816 200.093C-92.4149 200.093 -93.6579 198.849 -93.6579 197.314C-93.6579 195.779 -92.4149 194.535 -90.8816 194.535C-89.3483 194.535 -88.1053 195.779 -88.1053 197.314C-88.1053 198.849 -89.3483 200.093 -90.8816 200.093Z"
        fill={fill}
      />
      <path
        d="M-90.8816 227.884C-92.4149 227.884 -93.6579 226.639 -93.6579 225.105C-93.6579 223.57 -92.4149 222.326 -90.8816 222.326C-89.3483 222.326 -88.1053 223.57 -88.1053 225.105C-88.1053 226.639 -89.3483 227.884 -90.8816 227.884Z"
        fill={fill}
      />
      <path
        d="M-90.8816 255.674C-92.4149 255.674 -93.6579 254.43 -93.6579 252.895C-93.6579 251.361 -92.4149 250.116 -90.8816 250.116C-89.3483 250.116 -88.1053 251.361 -88.1053 252.895C-88.1053 254.43 -89.3483 255.674 -90.8816 255.674Z"
        fill={fill}
      />
      <path
        d="M-90.8816 283.465C-92.4149 283.465 -93.6579 282.221 -93.6579 280.686C-93.6579 279.151 -92.4149 277.907 -90.8816 277.907C-89.3483 277.907 -88.1053 279.151 -88.1053 280.686C-88.1053 282.221 -89.3483 283.465 -90.8816 283.465Z"
        fill={fill}
      />
      <path
        d="M-90.8816 311.256C-92.4149 311.256 -93.6579 310.012 -93.6579 308.477C-93.6579 306.942 -92.4149 305.698 -90.8816 305.698C-89.3483 305.698 -88.1053 306.942 -88.1053 308.477C-88.1053 310.012 -89.3483 311.256 -90.8816 311.256Z"
        fill={fill}
      />
      <path
        d="M-90.8816 339.046C-92.4149 339.046 -93.6579 337.802 -93.6579 336.267C-93.6579 334.733 -92.4149 333.488 -90.8816 333.488C-89.3483 333.488 -88.1053 334.733 -88.1053 336.267C-88.1053 337.802 -89.3483 339.046 -90.8816 339.046Z"
        fill={fill}
      />
      <path
        d="M-90.8816 366.837C-92.4149 366.837 -93.6579 365.593 -93.6579 364.058C-93.6579 362.523 -92.4149 361.279 -90.8816 361.279C-89.3483 361.279 -88.1053 362.523 -88.1053 364.058C-88.1053 365.593 -89.3483 366.837 -90.8816 366.837Z"
        fill={fill}
      />
      <path
        d="M-118.645 5.55813C-120.178 5.55813 -121.421 4.3139 -121.421 2.77906C-121.421 1.24422 -120.178 -1.11105e-05 -118.645 -1.10435e-05C-117.111 -1.09764e-05 -115.868 1.24422 -115.868 2.77906C-115.868 4.3139 -117.111 5.55813 -118.645 5.55813Z"
        fill={fill}
      />
      <path
        d="M-118.645 33.3488C-120.178 33.3488 -121.421 32.1046 -121.421 30.5698C-121.421 29.0349 -120.178 27.7907 -118.645 27.7907C-117.111 27.7907 -115.868 29.0349 -115.868 30.5698C-115.868 32.1046 -117.111 33.3488 -118.645 33.3488Z"
        fill={fill}
      />
      <path
        d="M-118.645 61.1395C-120.178 61.1395 -121.421 59.8953 -121.421 58.3605C-121.421 56.8256 -120.178 55.5814 -118.645 55.5814C-117.111 55.5814 -115.868 56.8256 -115.868 58.3605C-115.868 59.8953 -117.111 61.1395 -118.645 61.1395Z"
        fill={fill}
      />
      <path
        d="M-118.645 88.9302C-120.178 88.9302 -121.421 87.686 -121.421 86.1512C-121.421 84.6163 -120.178 83.3721 -118.645 83.3721C-117.111 83.3721 -115.868 84.6163 -115.868 86.1512C-115.868 87.686 -117.111 88.9302 -118.645 88.9302Z"
        fill={fill}
      />
      <path
        d="M-118.645 116.721C-120.178 116.721 -121.421 115.477 -121.421 113.942C-121.421 112.407 -120.178 111.163 -118.645 111.163C-117.111 111.163 -115.868 112.407 -115.868 113.942C-115.868 115.477 -117.111 116.721 -118.645 116.721Z"
        fill={fill}
      />
      <path
        d="M-118.645 144.512C-120.178 144.512 -121.421 143.267 -121.421 141.733C-121.421 140.198 -120.178 138.953 -118.645 138.953C-117.111 138.953 -115.868 140.198 -115.868 141.733C-115.868 143.267 -117.111 144.512 -118.645 144.512Z"
        fill={fill}
      />
      <path
        d="M-118.645 172.302C-120.178 172.302 -121.421 171.058 -121.421 169.523C-121.421 167.988 -120.178 166.744 -118.645 166.744C-117.111 166.744 -115.868 167.988 -115.868 169.523C-115.868 171.058 -117.111 172.302 -118.645 172.302Z"
        fill={fill}
      />
      <path
        d="M-118.645 200.093C-120.178 200.093 -121.421 198.849 -121.421 197.314C-121.421 195.779 -120.178 194.535 -118.645 194.535C-117.111 194.535 -115.868 195.779 -115.868 197.314C-115.868 198.849 -117.111 200.093 -118.645 200.093Z"
        fill={fill}
      />
      <path
        d="M-118.645 227.884C-120.178 227.884 -121.421 226.639 -121.421 225.105C-121.421 223.57 -120.178 222.326 -118.645 222.326C-117.111 222.326 -115.868 223.57 -115.868 225.105C-115.868 226.639 -117.111 227.884 -118.645 227.884Z"
        fill={fill}
      />
      <path
        d="M-118.645 255.674C-120.178 255.674 -121.421 254.43 -121.421 252.895C-121.421 251.361 -120.178 250.116 -118.645 250.116C-117.111 250.116 -115.868 251.361 -115.868 252.895C-115.868 254.43 -117.111 255.674 -118.645 255.674Z"
        fill={fill}
      />
      <path
        d="M-118.645 283.465C-120.178 283.465 -121.421 282.221 -121.421 280.686C-121.421 279.151 -120.178 277.907 -118.645 277.907C-117.111 277.907 -115.868 279.151 -115.868 280.686C-115.868 282.221 -117.111 283.465 -118.645 283.465Z"
        fill={fill}
      />
      <path
        d="M-118.645 311.256C-120.178 311.256 -121.421 310.012 -121.421 308.477C-121.421 306.942 -120.178 305.698 -118.645 305.698C-117.111 305.698 -115.868 306.942 -115.868 308.477C-115.868 310.012 -117.111 311.256 -118.645 311.256Z"
        fill={fill}
      />
      <path
        d="M-118.645 339.046C-120.178 339.046 -121.421 337.802 -121.421 336.267C-121.421 334.733 -120.178 333.488 -118.645 333.488C-117.111 333.488 -115.868 334.733 -115.868 336.267C-115.868 337.802 -117.111 339.046 -118.645 339.046Z"
        fill={fill}
      />
      <path
        d="M-118.645 366.837C-120.178 366.837 -121.421 365.593 -121.421 364.058C-121.421 362.523 -120.178 361.279 -118.645 361.279C-117.111 361.279 -115.868 362.523 -115.868 364.058C-115.868 365.593 -117.111 366.837 -118.645 366.837Z"
        fill={fill}
      />
      <path
        d="M-146.408 5.55813C-147.941 5.55813 -149.184 4.3139 -149.184 2.77906C-149.184 1.24422 -147.941 -1.2324e-05 -146.408 -1.2257e-05C-144.875 -1.219e-05 -143.632 1.24422 -143.632 2.77906C-143.632 4.3139 -144.875 5.55813 -146.408 5.55813Z"
        fill={fill}
      />
      <path
        d="M-146.408 33.3488C-147.941 33.3488 -149.184 32.1046 -149.184 30.5698C-149.184 29.0349 -147.941 27.7907 -146.408 27.7907C-144.875 27.7907 -143.632 29.0349 -143.632 30.5698C-143.632 32.1046 -144.875 33.3488 -146.408 33.3488Z"
        fill={fill}
      />
      <path
        d="M-146.408 61.1395C-147.941 61.1395 -149.184 59.8953 -149.184 58.3605C-149.184 56.8256 -147.941 55.5814 -146.408 55.5814C-144.875 55.5814 -143.632 56.8256 -143.632 58.3605C-143.632 59.8953 -144.875 61.1395 -146.408 61.1395Z"
        fill={fill}
      />
      <path
        d="M-146.408 88.9302C-147.941 88.9302 -149.184 87.686 -149.184 86.1512C-149.184 84.6163 -147.941 83.3721 -146.408 83.3721C-144.875 83.3721 -143.632 84.6163 -143.632 86.1512C-143.632 87.686 -144.875 88.9302 -146.408 88.9302Z"
        fill={fill}
      />
      <path
        d="M-146.408 116.721C-147.941 116.721 -149.184 115.477 -149.184 113.942C-149.184 112.407 -147.941 111.163 -146.408 111.163C-144.875 111.163 -143.632 112.407 -143.632 113.942C-143.632 115.477 -144.875 116.721 -146.408 116.721Z"
        fill={fill}
      />
      <path
        d="M-146.408 144.512C-147.941 144.512 -149.184 143.267 -149.184 141.733C-149.184 140.198 -147.941 138.953 -146.408 138.953C-144.875 138.953 -143.632 140.198 -143.632 141.733C-143.632 143.267 -144.875 144.512 -146.408 144.512Z"
        fill={fill}
      />
      <path
        d="M-146.408 172.302C-147.941 172.302 -149.184 171.058 -149.184 169.523C-149.184 167.988 -147.941 166.744 -146.408 166.744C-144.875 166.744 -143.632 167.988 -143.632 169.523C-143.632 171.058 -144.875 172.302 -146.408 172.302Z"
        fill={fill}
      />
      <path
        d="M-146.408 200.093C-147.941 200.093 -149.184 198.849 -149.184 197.314C-149.184 195.779 -147.941 194.535 -146.408 194.535C-144.875 194.535 -143.632 195.779 -143.632 197.314C-143.632 198.849 -144.875 200.093 -146.408 200.093Z"
        fill={fill}
      />
      <path
        d="M-146.408 227.884C-147.941 227.884 -149.184 226.639 -149.184 225.105C-149.184 223.57 -147.941 222.326 -146.408 222.326C-144.875 222.326 -143.632 223.57 -143.632 225.105C-143.632 226.639 -144.875 227.884 -146.408 227.884Z"
        fill={fill}
      />
      <path
        d="M-146.408 255.674C-147.941 255.674 -149.184 254.43 -149.184 252.895C-149.184 251.361 -147.941 250.116 -146.408 250.116C-144.875 250.116 -143.632 251.361 -143.632 252.895C-143.632 254.43 -144.875 255.674 -146.408 255.674Z"
        fill={fill}
      />
      <path
        d="M-146.408 283.465C-147.941 283.465 -149.184 282.221 -149.184 280.686C-149.184 279.151 -147.941 277.907 -146.408 277.907C-144.875 277.907 -143.632 279.151 -143.632 280.686C-143.632 282.221 -144.875 283.465 -146.408 283.465Z"
        fill={fill}
      />
      <path
        d="M-146.408 311.256C-147.941 311.256 -149.184 310.012 -149.184 308.477C-149.184 306.942 -147.941 305.698 -146.408 305.698C-144.875 305.698 -143.632 306.942 -143.632 308.477C-143.632 310.012 -144.875 311.256 -146.408 311.256Z"
        fill={fill}
      />
      <path
        d="M-146.408 339.046C-147.941 339.046 -149.184 337.802 -149.184 336.267C-149.184 334.733 -147.941 333.488 -146.408 333.488C-144.875 333.488 -143.632 334.733 -143.632 336.267C-143.632 337.802 -144.875 339.046 -146.408 339.046Z"
        fill={fill}
      />
      <path
        d="M-146.408 366.837C-147.941 366.837 -149.184 365.593 -149.184 364.058C-149.184 362.523 -147.941 361.279 -146.408 361.279C-144.875 361.279 -143.632 362.523 -143.632 364.058C-143.632 365.593 -144.875 366.837 -146.408 366.837Z"
        fill={fill}
      />
      <path
        d="M-174.171 5.55813C-175.704 5.55813 -176.947 4.31389 -176.947 2.77906C-176.947 1.24422 -175.704 -1.35376e-05 -174.171 -1.34706e-05C-172.638 -1.34036e-05 -171.395 1.24422 -171.395 2.77906C-171.395 4.31389 -172.638 5.55813 -174.171 5.55813Z"
        fill={fill}
      />
      <path
        d="M-174.171 33.3488C-175.704 33.3488 -176.947 32.1046 -176.947 30.5698C-176.947 29.0349 -175.704 27.7907 -174.171 27.7907C-172.638 27.7907 -171.395 29.0349 -171.395 30.5698C-171.395 32.1046 -172.638 33.3488 -174.171 33.3488Z"
        fill={fill}
      />
      <path
        d="M-174.171 61.1395C-175.704 61.1395 -176.947 59.8953 -176.947 58.3605C-176.947 56.8256 -175.704 55.5814 -174.171 55.5814C-172.638 55.5814 -171.395 56.8256 -171.395 58.3605C-171.395 59.8953 -172.638 61.1395 -174.171 61.1395Z"
        fill={fill}
      />
      <path
        d="M-174.171 88.9302C-175.704 88.9302 -176.947 87.686 -176.947 86.1512C-176.947 84.6163 -175.704 83.3721 -174.171 83.3721C-172.638 83.3721 -171.395 84.6163 -171.395 86.1512C-171.395 87.686 -172.638 88.9302 -174.171 88.9302Z"
        fill={fill}
      />
      <path
        d="M-174.171 116.721C-175.704 116.721 -176.947 115.477 -176.947 113.942C-176.947 112.407 -175.704 111.163 -174.171 111.163C-172.638 111.163 -171.395 112.407 -171.395 113.942C-171.395 115.477 -172.638 116.721 -174.171 116.721Z"
        fill={fill}
      />
      <path
        d="M-174.171 144.512C-175.704 144.512 -176.947 143.267 -176.947 141.733C-176.947 140.198 -175.704 138.953 -174.171 138.953C-172.638 138.953 -171.395 140.198 -171.395 141.733C-171.395 143.267 -172.638 144.512 -174.171 144.512Z"
        fill={fill}
      />
      <path
        d="M-174.171 172.302C-175.704 172.302 -176.947 171.058 -176.947 169.523C-176.947 167.988 -175.704 166.744 -174.171 166.744C-172.638 166.744 -171.395 167.988 -171.395 169.523C-171.395 171.058 -172.638 172.302 -174.171 172.302Z"
        fill={fill}
      />
      <path
        d="M-174.171 200.093C-175.704 200.093 -176.947 198.849 -176.947 197.314C-176.947 195.779 -175.704 194.535 -174.171 194.535C-172.638 194.535 -171.395 195.779 -171.395 197.314C-171.395 198.849 -172.638 200.093 -174.171 200.093Z"
        fill={fill}
      />
      <path
        d="M-174.171 227.884C-175.704 227.884 -176.947 226.639 -176.947 225.105C-176.947 223.57 -175.704 222.326 -174.171 222.326C-172.638 222.326 -171.395 223.57 -171.395 225.105C-171.395 226.639 -172.638 227.884 -174.171 227.884Z"
        fill={fill}
      />
      <path
        d="M-174.171 255.674C-175.704 255.674 -176.947 254.43 -176.947 252.895C-176.947 251.361 -175.704 250.116 -174.171 250.116C-172.638 250.116 -171.395 251.361 -171.395 252.895C-171.395 254.43 -172.638 255.674 -174.171 255.674Z"
        fill={fill}
      />
      <path
        d="M-174.171 283.465C-175.704 283.465 -176.947 282.221 -176.947 280.686C-176.947 279.151 -175.704 277.907 -174.171 277.907C-172.638 277.907 -171.395 279.151 -171.395 280.686C-171.395 282.221 -172.638 283.465 -174.171 283.465Z"
        fill={fill}
      />
      <path
        d="M-174.171 311.256C-175.704 311.256 -176.947 310.012 -176.947 308.477C-176.947 306.942 -175.704 305.698 -174.171 305.698C-172.638 305.698 -171.395 306.942 -171.395 308.477C-171.395 310.012 -172.638 311.256 -174.171 311.256Z"
        fill={fill}
      />
      <path
        d="M-174.171 339.046C-175.704 339.046 -176.947 337.802 -176.947 336.267C-176.947 334.733 -175.704 333.488 -174.171 333.488C-172.638 333.488 -171.395 334.733 -171.395 336.267C-171.395 337.802 -172.638 339.046 -174.171 339.046Z"
        fill={fill}
      />
      <path
        d="M-174.171 366.837C-175.704 366.837 -176.947 365.593 -176.947 364.058C-176.947 362.523 -175.704 361.279 -174.171 361.279C-172.638 361.279 -171.395 362.523 -171.395 364.058C-171.395 365.593 -172.638 366.837 -174.171 366.837Z"
        fill={fill}
      />
      <path
        d="M-201.934 5.55813C-203.468 5.55813 -204.711 4.31389 -204.711 2.77906C-204.711 1.24422 -203.468 -1.47512e-05 -201.934 -1.46842e-05C-200.401 -1.46171e-05 -199.158 1.24422 -199.158 2.77906C-199.158 4.31389 -200.401 5.55813 -201.934 5.55813Z"
        fill={fill}
      />
      <path
        d="M-201.934 33.3488C-203.468 33.3488 -204.711 32.1046 -204.711 30.5698C-204.711 29.0349 -203.468 27.7907 -201.934 27.7907C-200.401 27.7907 -199.158 29.0349 -199.158 30.5698C-199.158 32.1046 -200.401 33.3488 -201.934 33.3488Z"
        fill={fill}
      />
      <path
        d="M-201.934 61.1395C-203.468 61.1395 -204.711 59.8953 -204.711 58.3605C-204.711 56.8256 -203.468 55.5814 -201.934 55.5814C-200.401 55.5814 -199.158 56.8256 -199.158 58.3605C-199.158 59.8953 -200.401 61.1395 -201.934 61.1395Z"
        fill={fill}
      />
      <path
        d="M-201.934 88.9302C-203.468 88.9302 -204.711 87.686 -204.711 86.1512C-204.711 84.6163 -203.468 83.3721 -201.934 83.3721C-200.401 83.3721 -199.158 84.6163 -199.158 86.1512C-199.158 87.686 -200.401 88.9302 -201.934 88.9302Z"
        fill={fill}
      />
      <path
        d="M-201.934 116.721C-203.468 116.721 -204.711 115.477 -204.711 113.942C-204.711 112.407 -203.468 111.163 -201.934 111.163C-200.401 111.163 -199.158 112.407 -199.158 113.942C-199.158 115.477 -200.401 116.721 -201.934 116.721Z"
        fill={fill}
      />
      <path
        d="M-201.934 144.512C-203.468 144.512 -204.711 143.267 -204.711 141.733C-204.711 140.198 -203.468 138.953 -201.934 138.953C-200.401 138.953 -199.158 140.198 -199.158 141.733C-199.158 143.267 -200.401 144.512 -201.934 144.512Z"
        fill={fill}
      />
      <path
        d="M-201.934 172.302C-203.468 172.302 -204.711 171.058 -204.711 169.523C-204.711 167.988 -203.468 166.744 -201.934 166.744C-200.401 166.744 -199.158 167.988 -199.158 169.523C-199.158 171.058 -200.401 172.302 -201.934 172.302Z"
        fill={fill}
      />
      <path
        d="M-201.934 200.093C-203.468 200.093 -204.711 198.849 -204.711 197.314C-204.711 195.779 -203.468 194.535 -201.934 194.535C-200.401 194.535 -199.158 195.779 -199.158 197.314C-199.158 198.849 -200.401 200.093 -201.934 200.093Z"
        fill={fill}
      />
      <path
        d="M-201.934 227.884C-203.468 227.884 -204.711 226.639 -204.711 225.105C-204.711 223.57 -203.468 222.326 -201.934 222.326C-200.401 222.326 -199.158 223.57 -199.158 225.105C-199.158 226.639 -200.401 227.884 -201.934 227.884Z"
        fill={fill}
      />
      <path
        d="M-201.934 255.674C-203.468 255.674 -204.711 254.43 -204.711 252.895C-204.711 251.361 -203.468 250.116 -201.934 250.116C-200.401 250.116 -199.158 251.361 -199.158 252.895C-199.158 254.43 -200.401 255.674 -201.934 255.674Z"
        fill={fill}
      />
      <path
        d="M-201.934 283.465C-203.468 283.465 -204.711 282.221 -204.711 280.686C-204.711 279.151 -203.468 277.907 -201.934 277.907C-200.401 277.907 -199.158 279.151 -199.158 280.686C-199.158 282.221 -200.401 283.465 -201.934 283.465Z"
        fill={fill}
      />
      <path
        d="M-201.934 311.256C-203.468 311.256 -204.711 310.012 -204.711 308.477C-204.711 306.942 -203.468 305.698 -201.934 305.698C-200.401 305.698 -199.158 306.942 -199.158 308.477C-199.158 310.012 -200.401 311.256 -201.934 311.256Z"
        fill={fill}
      />
      <path
        d="M-201.934 339.046C-203.468 339.046 -204.711 337.802 -204.711 336.267C-204.711 334.733 -203.468 333.488 -201.934 333.488C-200.401 333.488 -199.158 334.733 -199.158 336.267C-199.158 337.802 -200.401 339.046 -201.934 339.046Z"
        fill={fill}
      />
      <path
        d="M-201.934 366.837C-203.468 366.837 -204.711 365.593 -204.711 364.058C-204.711 362.523 -203.468 361.279 -201.934 361.279C-200.401 361.279 -199.158 362.523 -199.158 364.058C-199.158 365.593 -200.401 366.837 -201.934 366.837Z"
        fill={fill}
      />
      <path
        d="M-229.697 5.55812C-231.231 5.55812 -232.474 4.31389 -232.474 2.77905C-232.474 1.24422 -231.231 -1.59647e-05 -229.697 -1.58977e-05C-228.164 -1.58307e-05 -226.921 1.24422 -226.921 2.77905C-226.921 4.31389 -228.164 5.55812 -229.697 5.55812Z"
        fill={fill}
      />
      <path
        d="M-229.697 33.3488C-231.231 33.3488 -232.474 32.1046 -232.474 30.5698C-232.474 29.0349 -231.231 27.7907 -229.697 27.7907C-228.164 27.7907 -226.921 29.0349 -226.921 30.5698C-226.921 32.1046 -228.164 33.3488 -229.697 33.3488Z"
        fill={fill}
      />
      <path
        d="M-229.697 61.1395C-231.231 61.1395 -232.474 59.8953 -232.474 58.3604C-232.474 56.8256 -231.231 55.5814 -229.697 55.5814C-228.164 55.5814 -226.921 56.8256 -226.921 58.3605C-226.921 59.8953 -228.164 61.1395 -229.697 61.1395Z"
        fill={fill}
      />
      <path
        d="M-229.697 88.9302C-231.231 88.9302 -232.474 87.686 -232.474 86.1512C-232.474 84.6163 -231.231 83.3721 -229.697 83.3721C-228.164 83.3721 -226.921 84.6163 -226.921 86.1512C-226.921 87.686 -228.164 88.9302 -229.697 88.9302Z"
        fill={fill}
      />
      <path
        d="M-229.697 116.721C-231.231 116.721 -232.474 115.477 -232.474 113.942C-232.474 112.407 -231.231 111.163 -229.697 111.163C-228.164 111.163 -226.921 112.407 -226.921 113.942C-226.921 115.477 -228.164 116.721 -229.697 116.721Z"
        fill={fill}
      />
      <path
        d="M-229.697 144.512C-231.231 144.512 -232.474 143.267 -232.474 141.733C-232.474 140.198 -231.231 138.953 -229.697 138.953C-228.164 138.953 -226.921 140.198 -226.921 141.733C-226.921 143.267 -228.164 144.512 -229.697 144.512Z"
        fill={fill}
      />
      <path
        d="M-229.697 172.302C-231.231 172.302 -232.474 171.058 -232.474 169.523C-232.474 167.988 -231.231 166.744 -229.697 166.744C-228.164 166.744 -226.921 167.988 -226.921 169.523C-226.921 171.058 -228.164 172.302 -229.697 172.302Z"
        fill={fill}
      />
      <path
        d="M-229.697 200.093C-231.231 200.093 -232.474 198.849 -232.474 197.314C-232.474 195.779 -231.231 194.535 -229.697 194.535C-228.164 194.535 -226.921 195.779 -226.921 197.314C-226.921 198.849 -228.164 200.093 -229.697 200.093Z"
        fill={fill}
      />
      <path
        d="M-229.697 227.884C-231.231 227.884 -232.474 226.639 -232.474 225.105C-232.474 223.57 -231.231 222.326 -229.697 222.326C-228.164 222.326 -226.921 223.57 -226.921 225.105C-226.921 226.639 -228.164 227.884 -229.697 227.884Z"
        fill={fill}
      />
      <path
        d="M-229.697 255.674C-231.231 255.674 -232.474 254.43 -232.474 252.895C-232.474 251.361 -231.231 250.116 -229.697 250.116C-228.164 250.116 -226.921 251.361 -226.921 252.895C-226.921 254.43 -228.164 255.674 -229.697 255.674Z"
        fill={fill}
      />
      <path
        d="M-229.697 283.465C-231.231 283.465 -232.474 282.221 -232.474 280.686C-232.474 279.151 -231.231 277.907 -229.697 277.907C-228.164 277.907 -226.921 279.151 -226.921 280.686C-226.921 282.221 -228.164 283.465 -229.697 283.465Z"
        fill={fill}
      />
      <path
        d="M-229.697 311.256C-231.231 311.256 -232.474 310.012 -232.474 308.477C-232.474 306.942 -231.231 305.698 -229.697 305.698C-228.164 305.698 -226.921 306.942 -226.921 308.477C-226.921 310.012 -228.164 311.256 -229.697 311.256Z"
        fill={fill}
      />
      <path
        d="M-229.697 339.046C-231.231 339.046 -232.474 337.802 -232.474 336.267C-232.474 334.733 -231.231 333.488 -229.697 333.488C-228.164 333.488 -226.921 334.733 -226.921 336.267C-226.921 337.802 -228.164 339.046 -229.697 339.046Z"
        fill={fill}
      />
      <path
        d="M-229.697 366.837C-231.231 366.837 -232.474 365.593 -232.474 364.058C-232.474 362.523 -231.231 361.279 -229.697 361.279C-228.164 361.279 -226.921 362.523 -226.921 364.058C-226.921 365.593 -228.164 366.837 -229.697 366.837Z"
        fill={fill}
      />
      <path
        d="M-257.461 5.55812C-258.994 5.55812 -260.237 4.31389 -260.237 2.77905C-260.237 1.24421 -258.994 -1.71783e-05 -257.461 -1.71113e-05C-255.927 -1.70443e-05 -254.684 1.24421 -254.684 2.77905C-254.684 4.31389 -255.927 5.55812 -257.461 5.55812Z"
        fill={fill}
      />
      <path
        d="M-257.461 33.3488C-258.994 33.3488 -260.237 32.1046 -260.237 30.5698C-260.237 29.0349 -258.994 27.7907 -257.461 27.7907C-255.927 27.7907 -254.684 29.0349 -254.684 30.5698C-254.684 32.1046 -255.927 33.3488 -257.461 33.3488Z"
        fill={fill}
      />
      <path
        d="M-257.461 61.1395C-258.994 61.1395 -260.237 59.8953 -260.237 58.3604C-260.237 56.8256 -258.994 55.5814 -257.461 55.5814C-255.927 55.5814 -254.684 56.8256 -254.684 58.3604C-254.684 59.8953 -255.927 61.1395 -257.461 61.1395Z"
        fill={fill}
      />
      <path
        d="M-257.461 88.9302C-258.994 88.9302 -260.237 87.686 -260.237 86.1512C-260.237 84.6163 -258.994 83.3721 -257.461 83.3721C-255.927 83.3721 -254.684 84.6163 -254.684 86.1512C-254.684 87.686 -255.927 88.9302 -257.461 88.9302Z"
        fill={fill}
      />
      <path
        d="M-257.461 116.721C-258.994 116.721 -260.237 115.477 -260.237 113.942C-260.237 112.407 -258.994 111.163 -257.461 111.163C-255.927 111.163 -254.684 112.407 -254.684 113.942C-254.684 115.477 -255.927 116.721 -257.461 116.721Z"
        fill={fill}
      />
      <path
        d="M-257.461 144.512C-258.994 144.512 -260.237 143.267 -260.237 141.733C-260.237 140.198 -258.994 138.953 -257.461 138.953C-255.927 138.953 -254.684 140.198 -254.684 141.733C-254.684 143.267 -255.927 144.512 -257.461 144.512Z"
        fill={fill}
      />
      <path
        d="M-257.461 172.302C-258.994 172.302 -260.237 171.058 -260.237 169.523C-260.237 167.988 -258.994 166.744 -257.461 166.744C-255.927 166.744 -254.684 167.988 -254.684 169.523C-254.684 171.058 -255.927 172.302 -257.461 172.302Z"
        fill={fill}
      />
      <path
        d="M-257.461 200.093C-258.994 200.093 -260.237 198.849 -260.237 197.314C-260.237 195.779 -258.994 194.535 -257.461 194.535C-255.927 194.535 -254.684 195.779 -254.684 197.314C-254.684 198.849 -255.927 200.093 -257.461 200.093Z"
        fill={fill}
      />
      <path
        d="M-257.461 227.884C-258.994 227.884 -260.237 226.639 -260.237 225.105C-260.237 223.57 -258.994 222.326 -257.461 222.326C-255.927 222.326 -254.684 223.57 -254.684 225.105C-254.684 226.639 -255.927 227.884 -257.461 227.884Z"
        fill={fill}
      />
      <path
        d="M-257.461 255.674C-258.994 255.674 -260.237 254.43 -260.237 252.895C-260.237 251.361 -258.994 250.116 -257.461 250.116C-255.927 250.116 -254.684 251.361 -254.684 252.895C-254.684 254.43 -255.927 255.674 -257.461 255.674Z"
        fill={fill}
      />
      <path
        d="M-257.461 283.465C-258.994 283.465 -260.237 282.221 -260.237 280.686C-260.237 279.151 -258.994 277.907 -257.461 277.907C-255.927 277.907 -254.684 279.151 -254.684 280.686C-254.684 282.221 -255.927 283.465 -257.461 283.465Z"
        fill={fill}
      />
      <path
        d="M-257.461 311.256C-258.994 311.256 -260.237 310.012 -260.237 308.477C-260.237 306.942 -258.994 305.698 -257.461 305.698C-255.927 305.698 -254.684 306.942 -254.684 308.477C-254.684 310.012 -255.927 311.256 -257.461 311.256Z"
        fill={fill}
      />
      <path
        d="M-257.461 339.046C-258.994 339.046 -260.237 337.802 -260.237 336.267C-260.237 334.733 -258.994 333.488 -257.461 333.488C-255.927 333.488 -254.684 334.733 -254.684 336.267C-254.684 337.802 -255.927 339.046 -257.461 339.046Z"
        fill={fill}
      />
      <path
        d="M-257.461 366.837C-258.994 366.837 -260.237 365.593 -260.237 364.058C-260.237 362.523 -258.994 361.279 -257.461 361.279C-255.927 361.279 -254.684 362.523 -254.684 364.058C-254.684 365.593 -255.927 366.837 -257.461 366.837Z"
        fill={fill}
      />
      <path
        d="M-285.224 5.55812C-286.757 5.55812 -288 4.31389 -288 2.77905C-288 1.24421 -286.757 -1.83919e-05 -285.224 -1.83248e-05C-283.69 -1.82578e-05 -282.447 1.24421 -282.447 2.77905C-282.447 4.31389 -283.69 5.55812 -285.224 5.55812Z"
        fill={fill}
      />
      <path
        d="M-285.224 33.3488C-286.757 33.3488 -288 32.1046 -288 30.5698C-288 29.0349 -286.757 27.7907 -285.224 27.7907C-283.69 27.7907 -282.447 29.0349 -282.447 30.5698C-282.447 32.1046 -283.69 33.3488 -285.224 33.3488Z"
        fill={fill}
      />
      <path
        d="M-285.224 61.1395C-286.757 61.1395 -288 59.8953 -288 58.3604C-288 56.8256 -286.757 55.5814 -285.224 55.5814C-283.69 55.5814 -282.447 56.8256 -282.447 58.3604C-282.447 59.8953 -283.69 61.1395 -285.224 61.1395Z"
        fill={fill}
      />
      <path
        d="M-285.224 88.9302C-286.757 88.9302 -288 87.686 -288 86.1512C-288 84.6163 -286.757 83.3721 -285.224 83.3721C-283.69 83.3721 -282.447 84.6163 -282.447 86.1512C-282.447 87.686 -283.69 88.9302 -285.224 88.9302Z"
        fill={fill}
      />
      <path
        d="M-285.224 116.721C-286.757 116.721 -288 115.477 -288 113.942C-288 112.407 -286.757 111.163 -285.224 111.163C-283.69 111.163 -282.447 112.407 -282.447 113.942C-282.447 115.477 -283.69 116.721 -285.224 116.721Z"
        fill={fill}
      />
      <path
        d="M-285.224 144.512C-286.757 144.512 -288 143.267 -288 141.733C-288 140.198 -286.757 138.953 -285.224 138.953C-283.69 138.953 -282.447 140.198 -282.447 141.733C-282.447 143.267 -283.69 144.512 -285.224 144.512Z"
        fill={fill}
      />
      <path
        d="M-285.224 172.302C-286.757 172.302 -288 171.058 -288 169.523C-288 167.988 -286.757 166.744 -285.224 166.744C-283.69 166.744 -282.447 167.988 -282.447 169.523C-282.447 171.058 -283.69 172.302 -285.224 172.302Z"
        fill={fill}
      />
      <path
        d="M-285.224 200.093C-286.757 200.093 -288 198.849 -288 197.314C-288 195.779 -286.757 194.535 -285.224 194.535C-283.69 194.535 -282.447 195.779 -282.447 197.314C-282.447 198.849 -283.69 200.093 -285.224 200.093Z"
        fill={fill}
      />
      <path
        d="M-285.224 227.884C-286.757 227.884 -288 226.639 -288 225.105C-288 223.57 -286.757 222.326 -285.224 222.326C-283.69 222.326 -282.447 223.57 -282.447 225.105C-282.447 226.639 -283.69 227.884 -285.224 227.884Z"
        fill={fill}
      />
      <path
        d="M-285.224 255.674C-286.757 255.674 -288 254.43 -288 252.895C-288 251.361 -286.757 250.116 -285.224 250.116C-283.69 250.116 -282.447 251.361 -282.447 252.895C-282.447 254.43 -283.69 255.674 -285.224 255.674Z"
        fill={fill}
      />
      <path
        d="M-285.224 283.465C-286.757 283.465 -288 282.221 -288 280.686C-288 279.151 -286.757 277.907 -285.224 277.907C-283.69 277.907 -282.447 279.151 -282.447 280.686C-282.447 282.221 -283.69 283.465 -285.224 283.465Z"
        fill={fill}
      />
      <path
        d="M-285.224 311.256C-286.757 311.256 -288 310.012 -288 308.477C-288 306.942 -286.757 305.698 -285.224 305.698C-283.69 305.698 -282.447 306.942 -282.447 308.477C-282.447 310.012 -283.69 311.256 -285.224 311.256Z"
        fill={fill}
      />
      <path
        d="M-285.224 339.046C-286.757 339.046 -288 337.802 -288 336.267C-288 334.733 -286.757 333.488 -285.224 333.488C-283.69 333.488 -282.447 334.733 -282.447 336.267C-282.447 337.802 -283.69 339.046 -285.224 339.046Z"
        fill={fill}
      />
      <path
        d="M-285.224 366.837C-286.757 366.837 -288 365.593 -288 364.058C-288 362.523 -286.757 361.279 -285.224 361.279C-283.69 361.279 -282.447 362.523 -282.447 364.058C-282.447 365.593 -283.69 366.837 -285.224 366.837Z"
        fill={fill}
      />
    </svg>
  );
};

export default CarouselDots;
