import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';

export const ScienceCarouselItem = styled.div`
  ${({ index, active }) =>
    index !== active
      ? `
    opacity: 0;
    pointer-events: none;
    transition: opacity .4s ${easings.inOut.cubic} 0s;`
      : `opacity: 1; pointer-events: all;
  transition: opacity 0.4s ${easings.inOut.cubic} 0.4s;`}
  height: 100%;

  ${mq.tabletL} {
    > div {
      // height: 100%;

      > div {
        // height: 100%;
      }
    }
  }
`;

export const ScienceCarouselLeft = styled.div`
  height: 100%;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
  position: relative;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  > p {
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    :nth-of-type(2) {
      margin-bottom: 3rem;
      margin-left: -5rem;
      margin-right: -5rem;
      width: calc(100% + 10rem);
    }

    ${mq.tabletL} {
      display: none;
    }
  }
`;

export const ScienceCarouselReadMore = styled.span`
  display: inline-block;
  margin-top: 3rem;

  button {
    border: none;
    background: transparent;
  }

  ${mq.tabletL} {
    display: none;
  }
`;

export const ScienceCarouselRight = styled.div`
  align-items: center;
  background-color: ${({ color }) => color};
  display: flex;
  flex-direction: column;
  bottom: -50vh;
  height: auto;
  justify-content: flex-start;
  left: -2.2rem;
  opacity: 0;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  position: absolute;
  pointer-events: none;
  right: -2.2rem;
  top: 0;
  text-align: center;
  transition: opacity 0.8s ${easings.out.quint};
  z-index: 3;

  ${mq.tabletL} {
    align-items: normal;
    background: transparent;
    height: 100%;
    justify-content: center;
    left: auto;
    opacity: 1;
    padding-left: 0;
    padding-right: 0;
    pointer-events: all;
    position: relative;
    right: auto;
    text-align: left;
    transition: opacity 0s;
    top: auto;
    bottom: auto;
  }

  h3 {
    ${clamp('font-size', 26, 42, undefined, undefined, false)};
    margin-bottom: 1.2rem;
    margin-left: calc(20% - 5rem);
    margin-right: calc(20% - 5rem);

    ${mq.tabletL} {
      margin-left: 0;
      margin-right: auto;
      padding-right: 12.5%;
    }
  }

  > p,
  button {
    opacity: 0;
    transition: opacity 0.4s ${easings.out.quint};

    ${mq.tabletL} {
      opacity: 1;
    }
  }

  > p {
    ${clamp('font-size', 15, 22, undefined, undefined, false)};
    margin-bottom: 2.8rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    :first-child {
      ${clamp('font-size', 14, 16, undefined, undefined, false)};
      margin-bottom: 1rem;
      transition: none;

      ${mq.tabletL} {
        margin-bottom: 2.8rem;
      }
    }

    :last-of-type {
      margin-bottom: 0;
    }

    ${mq.deskL} {
      margin-left: 0;
      max-width: 80rem;
    }
  }

  h3 + p {
    font-weight: 600;
  }

  &[data-modal-open='true'] {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.8s ${easings.out.quint};

    > p,
    button {
      opacity: 1;
      transition: opacity 0.4s ${easings.out.quint} 0.4s;

      :first-child {
        transition: none;
      }
    }
  }

  button {
    margin-top: 7vh;

    ${mq.tabletL} {
      display: none;
    }
  }
`;

export const ScienceCarouselImageWrap = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;

  > div {
    height: 100%;
  }

  ${mq.deskL} {
    min-height: 55rem;
  }

  :before {
    border-radius: 2rem;
    border: 0.1rem solid ${colors.light};
    content: '';
    height: calc(100% - (2 * 1.3rem));
    left: 1.3rem;
    position: absolute;
    top: 1.3rem;
    width: calc(100% - (2 * 1.3rem));
    z-index: 2;

    ${mq.tabletL} {
      height: calc(100% - (2 * 2.2rem));
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - (2 * 2.2rem));
    }
  }
`;

export const ScienceCarouselDots = styled.span`
  display: none;

  ${mq.tabletL} {
    display: inline-block;
    ${({ left }) => (left ? `left: -2.2rem;` : ``)}
    ${({ right }) => (right ? `right: -2.2rem;` : ``)}
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: 20%;
    bottom: 20%;
    width: calc(6.25% + 2.2rem);

    svg {
      height: 100%;
      position: relative;
      width: 100%;
    }
  }
`;
export const ScienceCarouselDotsMobile = styled.span`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  height: 21.3rem;
  ${clamp('width', 185, 367)}

  svg {
    height: 100%;
  }

  :nth-of-type(2n-1) {
    left: 0;
    transform: translateX(-120%) translateY(-50%);
  }
  :nth-of-type(2n) {
    right: 0;
    transform: translateX(120%) translateY(-50%);
  }
  ${mq.tabletP} {
    height: 31rem;
  }

  ${mq.tabletL} {
    display: none;
  }
`;
