import React, { useState, useEffect } from 'react';
import { Heading3, TextCaption } from '../TextStyles';
import Button from '../Button';
import CarouselDots from '../_svgs/CarouselDots';
import CarouselDotsDesktop from '../_svgs/CarouselDotsDesktop';
import Img from 'gatsby-image';
import SplitText from '../_animation/AnimateSplitText';
import FadeIn from '../_animation/AnimateFadeIn';
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';

import {
  ScienceCarouselItem,
  ScienceCarouselLeft,
  ScienceCarouselReadMore,
  ScienceCarouselRight,
  ScienceCarouselImageWrap,
  ScienceCarouselDotsMobile,
  ScienceCarouselDots,
} from './index.style';
import { buttonVariants } from '../Button/index.style';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import RichText from '../RichText';

const ScienceCarousel = (props) => {
  const { index } = props,
    { active } = props,
    { data } = props;

  const [modalOpen, setModalOpen] = useState(false);

  //close any open modals if active item is changed
  useEffect(() => {
    setModalOpen(false);
  }, [active]);

  const copy = <RichText content={data.text} delay={0.4}></RichText>;

  return (
    <ScienceCarouselItem data-index={index} index={index} active={active}>
      <ScienceCarouselDots left>
        <CarouselDotsDesktop fill={data.dotColor} />
      </ScienceCarouselDots>
      <Grid colCount={16}>
        <GridItem tabletL={4} tabletLStart={3}>
          <ScienceCarouselLeft>
            {data.year && (
              <TextCaption>
                <SplitText>{data.year}</SplitText>
              </TextCaption>
            )}
            <Heading3>
              <SplitText delay={0.2} type={`lines`} heading={true}>
                {data.title}
              </SplitText>
            </Heading3>
            <ScienceCarouselDotsMobile>
              <CarouselDots fill={data.dotColor} />
            </ScienceCarouselDotsMobile>
            <ScienceCarouselImageWrap>
              <FadeIn delay={0.7}>
                {' '}
                <Img fluid={data.image.fluid} />
              </FadeIn>
            </ScienceCarouselImageWrap>
            <ScienceCarouselDotsMobile>
              <CarouselDots fill={data.dotColor} />
            </ScienceCarouselDotsMobile>
            <ScienceCarouselReadMore>
              <Button
                variant={buttonVariants.plain}
                bold={false}
                iconRight={<IconArrowheadRight />}
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpen(true);
                }}
              >
                Read Chapter
              </Button>
            </ScienceCarouselReadMore>
          </ScienceCarouselLeft>
        </GridItem>

        <GridItem
          tabletL={8}
          tabletLStart={8}
          desk={7}
          deskStart={8}
          deskL={6}
          deskLStart={9}
        >
          <ScienceCarouselRight data-modal-open={modalOpen} color={data.color}>
            {data.year && (
              <TextCaption>
                <SplitText>{data.year}</SplitText>
              </TextCaption>
            )}
            <Heading3 as={`h3`}>
              <SplitText delay={0.2} type={`lines`} heading={true}>
                {data.title}
              </SplitText>
            </Heading3>

            {copy}

            <Button
              variant={buttonVariants.plain}
              iconLeft={<IconArrowheadLeft />}
              bold={false}
              onClick={(e) => {
                e.preventDefault();
                setModalOpen(false);
              }}
            >
              Back to timeline
            </Button>
          </ScienceCarouselRight>
        </GridItem>
      </Grid>{' '}
      <ScienceCarouselDots right>
        <CarouselDotsDesktop fill={data.dotColor} />
      </ScienceCarouselDots>
    </ScienceCarouselItem>
  );
};

export default ScienceCarousel;
