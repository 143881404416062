import React from 'react';
import PropTypes from 'prop-types';
import { Heading2, Heading3, TextBodyLarge } from '../TextStyles';
import Spacer from '../Spacer';
import Button from '../Button';
import Container from '../_layouts/Container';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import SplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

import { ScienceIntroWrapper, ScienceIntroMain } from './index.style';
import { buttonVariants } from '../Button/index.style';
import { breakpoints } from '../../styles/vars/breakpoints.style';
import Particles from '../Particles';
import { scrollToSection } from 'utils/utils';

gsap.registerPlugin(ScrollToPlugin);

const ScienceIntro = ({
  title,
  text,
  current,
  setCurrent,
  next,
  ethicsHero,
  guidelines,
}) => {
  const scrollToNext = () => {
    if (window.innerWidth <= breakpoints.tabletL) {
      if (next.current) {
        gsap.to(window, { scrollTo: next.current.offsetTop, duration: 1 });
      }
    } else {
      if (window.scroll.scrollTo) {
        if (next.current) {
          window.scroll.scrollTo(next.current);
        }
      }
    }
  };

  return (
    <ScienceIntroWrapper guidelines={guidelines} ethicsHero={ethicsHero}>
      {!ethicsHero && !guidelines ? (
        <Particles
          fade={false}
          gap={4}
          scale={13}
          xTarget={-50}
          yTarget={9.28}
          y={6.94}
          z={13}
          zDepth={40}
        />
      ) : (
        ``
      )}

      <Container>
        <ScienceIntroMain ethicsHero={ethicsHero}>
          {title && !ethicsHero ? (
            <Heading2 as={`h1`}>
              <SplitText type={`lines`} heading={true} delay={0.4}>
                {title}
              </SplitText>
            </Heading2>
          ) : title && ethicsHero ? (
            <Heading3 as={`h1`}>
              <SplitText type={`lines`} heading={true} delay={0.4}>
                {title}
              </SplitText>
            </Heading3>
          ) : (
            ``
          )}
          <Spacer size={[18, 24]} />
          {text ? (
            <TextBodyLarge>
              <SplitText delay={0.6}>{text}</SplitText>
            </TextBodyLarge>
          ) : (
            ``
          )}
          <Spacer size={30} />
          {!ethicsHero && !guidelines ? (
            <>
              <AnimateFadeIn delay={1}>
                <Button
                  iconRight={<IconArrowheadRight />}
                  aria-label={`Explore the story`}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('#science-research');
                  }}
                >
                  View our research
                </Button>
              </AnimateFadeIn>
              <Spacer size={[18, 30]} />{' '}
              <AnimateFadeIn delay={1.2}>
                <Button
                  iconRight={<IconArrowheadRight />}
                  variant={buttonVariants.plain}
                  aria-label={`View our research`}
                  bold={false}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToSection('#science-carousel');
                  }}
                >
                  Explore the story
                </Button>
              </AnimateFadeIn>
            </>
          ) : ethicsHero ? (
            <>
              <AnimateFadeIn delay={1}>
                <Button
                  iconRight={<IconArrowheadRight />}
                  aria-label={`The hume initiative`}
                  to={``}
                >
                  The hume initiative
                </Button>
              </AnimateFadeIn>
              <Spacer size={[18, 30]} />{' '}
              <AnimateFadeIn delay={1.2}>
                <Button
                  iconRight={<IconArrowheadRight />}
                  variant={buttonVariants.plain}
                  aria-label={`Learn more about ethics`}
                  bold={false}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToNext();
                  }}
                >
                  Learn more about ethics
                </Button>
              </AnimateFadeIn>
            </>
          ) : guidelines ? (
            <AnimateFadeIn delay={1}>
              <Button
                iconRight={<IconArrowheadRight />}
                aria-label={`The hume initiative`}
                to={``}
              >
                The hume initiative
              </Button>
            </AnimateFadeIn>
          ) : (
            ``
          )}
        </ScienceIntroMain>
      </Container>
    </ScienceIntroWrapper>
  );
};

ScienceIntro.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ScienceIntro;
