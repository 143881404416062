import React from 'react';

const LongDots = ({
  width = 92,
  height = 2,
  fill = '#353535',
  responsive = false,
  title = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };
  const labelId = title ? title.replace(/[^A-Z0-9]/gi, '_') : false;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 92 2"
      fill="none"
      aria-labelledby={labelId ? labelId : undefined}
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <g opacity="0.2">
        <circle cx="1" cy="1" r="1" fill={fill} />
        <circle cx="41" cy="1" r="1" fill={fill} />
        <circle cx="21" cy="1" r="1" fill={fill} />
        <circle cx="61" cy="1" r="1" fill={fill} />
        <circle cx="11" cy="1" r="1" fill={fill} />
        <circle cx="51" cy="1" r="1" fill={fill} />
        <circle cx="31" cy="1" r="1" fill={fill} />
        <circle cx="71" cy="1" r="1" fill={fill} />
        <circle cx="86" cy="1" r="1" fill={fill} />
        <circle cx="6" cy="1" r="1" fill={fill} />
        <circle cx="46" cy="1" r="1" fill={fill} />
        <circle cx="26" cy="1" r="1" fill={fill} />
        <circle cx="66" cy="1" r="1" fill={fill} />
        <circle cx="81" cy="1" r="1" fill={fill} />
        <circle cx="16" cy="1" r="1" fill={fill} />
        <circle cx="56" cy="1" r="1" fill={fill} />
        <circle cx="36" cy="1" r="1" fill={fill} />
        <circle cx="76" cy="1" r="1" fill={fill} />
        <circle cx="91" cy="1" r="1" fill={fill} />
      </g>
    </svg>
  );
};

export default LongDots;
