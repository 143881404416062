import React, { useState, useRef, useEffect } from 'react';
import { Heading2 } from '../TextStyles';
import Button from '../Button';
// import Slide1Img from './images/1.jpg'
// import Slide2Img from './images/2.jpg'
// import Slide3Img from './images/3.png'
// import Slide4Img from './images/4.jpg'
// import Slide5Img from './images/5.jpg'
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';
import ScienceCarouselItem from '../ScienceCarouselItem';

import SplitText from '../_animation/AnimateSplitText';

import gsap from 'gsap';
import Hammer from 'react-hammerjs';

import {
  ScienceCarouselNav,
  ScienceCarouselNavItem,
  ScienceCarouselMobileNavItem,
  ScienceCarouselNavText,
  ScienceCarouselWrapper,
  ScienceCarouselItemWrap,
  ScienceCarouselMobileNav,
  ScienceCarouselCounter,
  ScienceCarouselCurrent,
} from './index.style';
import { buttonVariants } from '../Button/index.style';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';

import CarouselLongDots from '../_svgs/CarouselLongDots';
import AnimateFadeIn from '../_animation/AnimateFadeIn';
import { breakpoints } from '../../styles/vars/breakpoints.style';

const ScienceCarousel = React.forwardRef((props, ref) => {
  const { current } = props,
    { setCurrent } = props,
    { title } = props,
    { data } = props;

  const [currentSubSection, setCurrentSubSection] = useState(1);

  const carouselRef = useRef(),
    navRef = useRef();

  const max = data.length;

  const handleSwipe = (e) => {
    if (window.innerWidth < breakpoints.tabletL) {
      if (e.direction === 4) {
        //left
        setPrev();
      } else if (e.direction === 2) {
        //right
        setNext();
      }
    }
  };

  const setPrev = () => {
    if (current === 4) {
      if (currentSubSection > 1) {
        setCurrentSubSection(currentSubSection - 1);
      }
      if (currentSubSection === 1) {
        setCurrent(current - 1);
      }
    } else if (current !== 0 || !current < 0) {
      setCurrent(current - 1);
    }
  };

  const setNext = () => {
    if (current === 4) {
      if (currentSubSection < 3) {
        setCurrentSubSection(currentSubSection + 1);
      }
      if (currentSubSection === 3) {
        setCurrent(current + 1);
      }
    } else if (current < max - 1) {
      setCurrent(current + 1);
    }
  };

  useEffect(() => {
    const updateBg = () => {
      const carousel = ref.current;

      if (data[current].color === `#353535`) {
        gsap.to(carousel, {
          backgroundColor: data[current].color,
          color: `#FFF4E8`,
          ease: 'circ.out',
          duration: 0.5,
        });
      } else {
        gsap.to(carousel, {
          backgroundColor: data[current].color,
          color: `#353535`,
          ease: 'circ.out',
          duration: 0.5,
        });
      }
    };

    updateBg();

    return () => {};
  }, [current, ref, data]);

  return (
    <ScienceCarouselWrapper
      data-active={current}
      ref={(carouselRef, ref)}
      id="science-carousel"
    >
      <Container>
        <Grid>
          <GridItem>
            {' '}
            <Heading2 as={`h2`}>
              <SplitText delay={1} type={`lines`} heading={true}>
                {title}
              </SplitText>
            </Heading2>
            <Hammer onSwipe={handleSwipe}>
              <ScienceCarouselItemWrap>
                {React.Children.toArray(
                  data.map((slide, slideIndex) => (
                    <ScienceCarouselItem
                      data-index={slideIndex}
                      index={slideIndex}
                      active={current}
                      data={slide}
                      max={max}
                    />
                  )),
                )}
              </ScienceCarouselItemWrap>
            </Hammer>
            <AnimateFadeIn delay={1}>
              <ScienceCarouselNav ref={navRef}>
                <ScienceCarouselNavItem
                  arrow={true}
                  current={current}
                  inactive={current === 0 ? true : false}
                  navArrow={true}
                >
                  <Button
                    variant={buttonVariants.plain}
                    onClick={(e) => {
                      e.preventDefault();
                      setPrev();
                    }}
                    ariaLabel={`Previous Slide`}
                    iconOnly={<IconArrowheadLeft title="Previous slide" />}
                  ></Button>
                </ScienceCarouselNavItem>

                {React.Children.toArray(
                  data.map((slide, slideIndex) => (
                    <>
                      {' '}
                      <ScienceCarouselNavItem
                        current={current}
                        className={`navItemDot`}
                        index={slideIndex}
                      >
                        <Button
                          variant={buttonVariants.plain}
                          data-index={slideIndex}
                          index={slideIndex}
                          current={current}
                          onClick={() => setCurrent(slideIndex)}
                          small={slide.research}
                          ariaLabel={slide.name}
                        >
                          <ScienceCarouselNavText>
                            {slide.name}
                          </ScienceCarouselNavText>
                        </Button>
                        {slideIndex !== data.length - 1 ? (
                          <CarouselLongDots />
                        ) : (
                          ``
                        )}
                      </ScienceCarouselNavItem>
                    </>
                  )),
                )}

                <ScienceCarouselNavItem
                  arrow={true}
                  current={current}
                  inactive={current === max - 1 ? true : false}
                  navArrow={true}
                >
                  <Button
                    variant={buttonVariants.plain}
                    onClick={(e) => {
                      e.preventDefault();
                      setNext();
                    }}
                    iconOnly={<IconArrowheadRight title="Next slide" />}
                    ariaLabel={`Next Slide`}
                  ></Button>
                </ScienceCarouselNavItem>
              </ScienceCarouselNav>
            </AnimateFadeIn>
            <ScienceCarouselMobileNav>
              <ScienceCarouselMobileNavItem
                arrow={true}
                current={current}
                inactive={current === 0 ? true : false}
                navArrow={true}
              >
                <Button
                  variant={buttonVariants.plain}
                  onClick={(e) => {
                    e.preventDefault();
                    setPrev();
                  }}
                  ariaLabel={`Previous Slide`}
                  iconLeft={<IconArrowheadLeft title="Previous slide" />}
                ></Button>
              </ScienceCarouselMobileNavItem>

              <ScienceCarouselCounter>
                <ScienceCarouselCurrent>{current + 1}</ScienceCarouselCurrent>{' '}
                of {data.length}
              </ScienceCarouselCounter>

              <ScienceCarouselMobileNavItem
                arrow={true}
                current={current}
                inactive={current === max - 1 ? true : false}
                navArrow={true}
              >
                <Button
                  variant={buttonVariants.plain}
                  onClick={(e) => {
                    e.preventDefault();
                    setNext();
                  }}
                  ariaLabel={`Next Slide`}
                  iconRight={<IconArrowheadRight title="Next slide" />}
                ></Button>
              </ScienceCarouselMobileNavItem>
            </ScienceCarouselMobileNav>
          </GridItem>
        </Grid>
      </Container>
    </ScienceCarouselWrapper>
  );
});

export default ScienceCarousel;
