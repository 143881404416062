//For Science Carousel Items that have more than one part to them

import React from 'react';
import { Heading2, Heading5, TextCaption } from '../TextStyles';

import {
  ScienceItem,
  ScienceItemLeft,
  ScienceItemRight,
  ScienceItemImageWrap,
  ResearchHeader,
} from './index.style';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';
import Spacer from '../Spacer';
import Img from 'gatsby-image';
import RichText from '../RichText';
import PageTransitionLink from '../PageTransitionLink';
import Routes from '../../routes';
import Button from '../Button';
import { buttonVariants, buttonThemes } from '../Button/index.style';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';

import AnimateImageIn from '../_animation/AnimateImageIn';
import AnimateFadeIn from '../_animation/AnimateFadeIn';
import AnimateSplitText from '../_animation/AnimateSplitText';
import { scrollToSection } from 'utils/utils';

const ScienceItemSection = (props) => {
  const {
    index,
    subheading,
    heading,
    text,
    buttonText,
    buttonTo,
    image,
    align,
    button,
  } = props;

  const copy = <RichText content={text} delay={0.4}></RichText>;

  return (
    <>
      {index === 0 && (
        <ResearchHeader id="science-research">
          <Spacer size={[48, 100]} />
          <Heading2>
            <AnimateSplitText>Our Research</AnimateSplitText>
          </Heading2>
        </ResearchHeader>
      )}

      {align === 'right' ? (
        <ScienceItem align={align} index={index}>
          <Container>
            <Grid colCount={16} align={align}>
              <GridItem tabletL={5} tabletLStart={3} desk={4} deskStart={4}>
                <ScienceItemLeft>
                  <ScienceItemImageWrap>
                    <AnimateImageIn delay={0} transparent>
                      <Img fluid={image.fluid} />
                    </AnimateImageIn>
                  </ScienceItemImageWrap>
                </ScienceItemLeft>
              </GridItem>

              <GridItem tabletL={6} tabletLStart={9} desk={5} deskStart={9}>
                <ScienceItemRight>
                  <TextCaption bold>
                    <AnimateSplitText>{subheading}</AnimateSplitText>
                  </TextCaption>
                  <Heading5>
                    <AnimateSplitText delay={0.2} type={`lines`} heading={true}>
                      {heading}
                    </AnimateSplitText>
                  </Heading5>
                  {copy}
                </ScienceItemRight>
              </GridItem>
            </Grid>
          </Container>
        </ScienceItem>
      ) : (
        <ScienceItem align={align} index={index}>
          <Container>
            <Grid colCount={16}>
              <GridItem tabletL={6} tabletLStart={3} desk={5} deskStart={4}>
                <ScienceItemRight>
                  <TextCaption bold>
                    <AnimateSplitText>{subheading}</AnimateSplitText>
                  </TextCaption>
                  <Heading5>
                    <AnimateSplitText delay={0.2} type={`lines`} heading={true}>
                      {heading}
                    </AnimateSplitText>
                  </Heading5>

                  {copy}
                  {button && buttonTo ? (
                    <AnimateFadeIn delay={1}>
                      <PageTransitionLink to={Routes.solutions}>
                        <Button
                          iconRight={<IconArrowheadRight />}
                          as="button"
                          aria-label="Explore our findings"
                          variant={buttonVariants.outline}
                          theme={buttonThemes.light}
                        >
                          {buttonText}
                        </Button>
                      </PageTransitionLink>
                    </AnimateFadeIn>
                  ) : button ? (
                    <AnimateFadeIn delay={1}>
                      <Button
                        iconRight={<IconArrowheadRight />}
                        as="button"
                        aria-label="Explore our findings"
                        variant={buttonVariants.outline}
                        theme={buttonThemes.light}
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToSection('#papers');
                        }}
                      >
                        {buttonText}
                      </Button>
                    </AnimateFadeIn>
                  ) : (
                    ``
                  )}
                </ScienceItemRight>
              </GridItem>

              <GridItem tabletL={5} tabletLStart={10} desk={4} deskStart={10}>
                <ScienceItemLeft>
                  <ScienceItemImageWrap>
                    <AnimateImageIn delay={0} transparent>
                      <Img fluid={image.fluid} />
                    </AnimateImageIn>
                  </ScienceItemImageWrap>
                </ScienceItemLeft>
              </GridItem>
            </Grid>
          </Container>
        </ScienceItem>
      )}
    </>
  );
};

export default ScienceItemSection;
