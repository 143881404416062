import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';

export const ResearchHeader = styled.div`
  color: ${colors.light};
  text-align: center;
  background-color: ${colors.humeBlack700};
`;

export const ScienceItem = styled.div`
  background: ${colors.humeBlack700};
  color: ${colors.light};
  opacity: 1;
  margin-top: -1px;
  pointer-events: all;
  transition: opacity 0.4s ${easings.inOut.cubic} 0.4s;
  height: 100%;

  ${({ index }) =>
    index === 0
      ? clamp('padding-top', 60, 120)
      : clamp('padding-top', 120, 210)}

  ${({ index }) =>
    index === 2
      ? `
  ${clamp('padding-bottom', 120, 210)}`
      : ``}

  ${mq.tabletL} {
    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  ${({ align }) =>
    align === 'right'
      ? `> div {
       > div {
         display: flex;
         flex-direction: column;

         ${mq.tabletL} {
               display: grid;
             }

         >div {
           :first-of-type {
             order: 2;
             ${mq.tabletL} {
               order: 1
             }
           }
           :last-of-type {
             order: 1;
${mq.tabletL} {
               order: 2
             }
           }
         }
       }
     }`
      : `> div {
       > div {
         display: flex;
         flex-direction: column;

           ${mq.tabletL} {
               display: grid;
             }

         >div {
           :first-of-type {
             order: 1;

             ${mq.tabletL} {
               order: 1
             }
           }
           :last-of-type {
             order: 2;

             ${mq.tabletL} {
               order: 2
             }
           }
         }
       }
     }`}
`;

export const ScienceItemLeft = styled.div`
  height: 100%;
  text-align: left;
  padding-top: 8.8rem;
  position: relative;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-top: 0rem;
  }

  img {
    object-fit: cover;
    width: 100%;
  }
`;

export const ScienceItemRight = styled.div`
  text-align: left;
  transition: opacity 0.8s ${easings.out.quint};
  z-index: 3;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    pointer-events: all;
    position: relative;
    text-align: left;
  }

  button {
    margin-top: 3.4rem;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    ${mq.deskL} {
      margin-left: 0;
      max-width: 80%;
    }

    > span {
      margin-bottom: 3rem;
    }

    :nth-of-type(2) {
      ${mq.deskL} {
        max-width: 65%;
      }
      > span {
        margin-bottom: 1.2rem;
      }
    }

    :last-child {
      > span {
        margin-bottom: 0;
      }
    }
  }
`;

export const ScienceItemImageWrap = styled.div`
  overflow: hidden;
  position: relative;

  :before {
    border-radius: 2rem;
    border: 0.1rem solid ${colors.light};
    content: '';
    height: calc(100% - (2 * 1.3rem));
    left: 1.3rem;
    position: absolute;
    top: 1.3rem;
    width: calc(100% - (2 * 1.3rem));
    z-index: 2;

    ${mq.tabletL} {
      height: calc(100% - (2 * 2.2rem));
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: calc(100% - (2 * 2.2rem));
    }
  }
`;
