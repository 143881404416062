import React, { useState, useRef, useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';

import SEO from '../components/SEO';
import ScrollSection from '../components/ScrollSection';
import ScienceIntro from '../components/ScienceIntro';
import ScienceCarousel from '../components/ScienceCarousel';
import ScienceResearch from '../components/ScienceResearch';
import Footer from '../components/Footer';
import ScienceItemSection from '../components/ScienceDetailedItem';

import { ContextState } from 'components/Providers';

import { breakpoints } from '../styles/vars/breakpoints.style';

gsap.registerPlugin(ScrollToPlugin);

const IndexPage = ({ data }) => {
  const {
    metaDescription,
    introTitle,
    introText,
    carouselTitle,
    carouselItems,
    fullSpectrumSubheading,
    fullSpectrumHeading,
    fullSpectrumText,
    fullSpectrumButtonText,
    fullSpectrumImage,
    exploringEmotionSubheading,
    exploringEmotionHeading,
    exploringEmotionText,
    exploringEmotionImage,
    theVoiceSubheading,
    theVoiceHeading,
    theVoiceText,
    theVoiceButtonText,
    theVoiceImage,
    researchText,
    researchTitle,
    researchItems,
  } = data.allContentfulPageScience.nodes[0];

  const { scrollSection } = useContext(ContextState);

  const scrollToFindings = () => {
    if (window.innerWidth <= breakpoints.tabletL) {
      if (findingsSectionRef.current) {
        gsap.to(window, {
          scrollTo: findingsSectionRef.current.offsetTop,
          duration: 1,
        });
      }
    } else {
      if (window.scroll.scrollTo) {
        if (findingsSectionRef.current) {
          window.scroll.scrollTo(findingsSectionRef.current);
        }
      }
    }
  };

  useEffect(() => {
    if (scrollSection) {
      scrollToFindings();
    }
  }, [scrollSection]);

  const [current, setCurrent] = useState(0);

  const carouselSectionRef = useRef(),
    findingsSectionRef = useRef();

  return (
    <>
      <SEO
        title="Science"
        description={metaDescription.metaDescription}
        pathname={`science`}
      />

      <ScrollSection>
        <ScienceIntro
          title={introTitle}
          text={introText}
          current={current}
          setCurrent={setCurrent}
          next={carouselSectionRef}
        />
      </ScrollSection>

      <ScrollSection>
        <ScienceCarousel
          current={current}
          setCurrent={setCurrent}
          ref={carouselSectionRef}
          title={carouselTitle}
          data={carouselItems}
        />
      </ScrollSection>

      <ScrollSection>
        <ScienceItemSection
          subheading={fullSpectrumSubheading}
          heading={fullSpectrumHeading}
          text={fullSpectrumText}
          image={fullSpectrumImage}
          buttonText={fullSpectrumButtonText}
          findings={findingsSectionRef}
          button={true}
          align={`left`}
          scrollToFindings={scrollToFindings}
          index={0}
        />
      </ScrollSection>

      <ScrollSection>
        <ScienceItemSection
          subheading={exploringEmotionSubheading}
          heading={exploringEmotionHeading}
          text={exploringEmotionText}
          image={exploringEmotionImage}
          align={`right`}
          index={1}
        />
      </ScrollSection>

      <ScrollSection>
        <ScienceItemSection
          subheading={theVoiceSubheading}
          heading={theVoiceHeading}
          text={theVoiceText}
          buttonTo={`/products`}
          buttonText={theVoiceButtonText}
          image={theVoiceImage}
          align={`left`}
          button={true}
          index={2}
        />
      </ScrollSection>

      <ScrollSection data-scroll-section="papers">
        <ScienceResearch
          title={researchTitle}
          text={researchText}
          items={researchItems}
          ref={findingsSectionRef}
        ></ScienceResearch>
      </ScrollSection>

      <Footer />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query SciencePageQuery {
    allContentfulPageScience {
      nodes {
        metaDescription {
          metaDescription
        }
        introText
        introTitle
        carouselTitle
        carouselItems {
          title
          year
          name
          text {
            raw
          }
          color
          image {
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
        }

        fullSpectrumSubheading
        fullSpectrumHeading
        fullSpectrumText {
          raw
        }
        fullSpectrumButtonText
        fullSpectrumImage {
          fluid(maxWidth: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        exploringEmotionSubheading
        exploringEmotionHeading
        exploringEmotionText {
          raw
        }
        exploringEmotionImage {
          fluid(maxWidth: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        theVoiceSubheading
        theVoiceHeading
        theVoiceText {
          raw
        }
        theVoiceButtonText
        theVoiceImage {
          fluid(maxWidth: 900) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }

        researchTitle
        researchText
        researchItems {
          title
          url
          logo {
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;
