import React, { useState } from 'react';
import Button from '../Button';
import { Heading3, TextBodySmall, TextBodyLarge } from '../TextStyles';
import Img from 'gatsby-image';

import {
  ScienceResearch,
  ScienceCarouselLeft,
  ScienceCarouselRight,
  ScienceCarouselRightInner,
  ScienceCarouselResearchList,
  ScienceCarouselResearchListWrap,
  ScienceCarouselResearchListItem,
  ScienceCarouselDesktopHover,
  ScienceCarouselResearchCounter,
  ScienceResearchCounter,
  ScienceResearchNav,
  ScienceResearchNavItem,
  ScienceResearchListItemInner,
  ScienceCarouselDesktopHoverLink,
} from './index.style';
import { buttonThemes, buttonVariants } from '../Button/index.style';
import Container from '../_layouts/Container';
import Grid from '../_layouts/Grid';
import GridItem from '../_layouts/GridItem';

import IconArrowheadLeft from '../_svgs/IconArrowheadLeft';
import IconArrowheadRight from '../_svgs/IconArrowheadRight';
import { colors } from '../../styles/vars/colors.style';
import AnimateSplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

const ScienceCarousel = React.forwardRef((props, ref) => {
  const { index } = props,
    { active } = props,
    { title } = props,
    { text } = props,
    { items } = props;

  const [currentListItem, setCurrentListItem] = useState(0);

  const listCount = Math.ceil(items.length / 6);

  const itemCounter = [];

  for (let index = 0; index < listCount; index++) {
    itemCounter.push(
      <ScienceCarouselResearchCounter
        key={index}
        count={index}
        current={currentListItem}
        onClick={(e) => {
          setCurrentListItem(index);
        }}
      ></ScienceCarouselResearchCounter>,
    );
  }

  const setPrev = () => {
    if (currentListItem > 0) {
      setCurrentListItem(currentListItem - 1);
    }
  };

  const setNext = () => {
    if (currentListItem < items.length - 1) {
      setCurrentListItem(currentListItem + 1);
    }
  };

  return (
    <ScienceResearch
      id={'papers'}
      data-index={index}
      index={index}
      active={active}
      ref={ref}
    >
      <Container>
        <Grid colCount={16}>
          <GridItem>
            <ScienceCarouselLeft>
              <Heading3>
                <AnimateSplitText type={`lines`} heading={true}>
                  {title}
                </AnimateSplitText>
              </Heading3>
              <TextBodyLarge>
                <AnimateSplitText delay={0.2}>{text}</AnimateSplitText>
              </TextBodyLarge>
            </ScienceCarouselLeft>
          </GridItem>

          <GridItem tabletL={14} tabletLStart={2} desk={12} deskStart={3}>
            <ScienceCarouselRight color={items.color}>
              <ScienceCarouselRightInner>
                <ScienceCarouselResearchListWrap>
                  <ScienceCarouselResearchList
                    key={index + 1}
                    num={index}
                    current={currentListItem}
                  >
                    {React.Children.toArray(
                      items.map((slide, slideIndex) => (
                        <ScienceCarouselResearchListItem
                          index={slideIndex}
                          current={currentListItem}
                        >
                          <ScienceResearchListItemInner>
                            <AnimateFadeIn delay={0.2 * slideIndex + 0.1}>
                              <Img fluid={slide.logo.fluid} loading={`eager`} />

                              <TextBodySmall>{slide.title}</TextBodySmall>

                              <Button
                                as={`a`}
                                variant={buttonVariants.plain}
                                theme={buttonThemes.light}
                                bold={false}
                                href={slide.url}
                                newTab={true}
                                iconRight={
                                  <IconArrowheadRight
                                    title="Read Article"
                                    fill={colors.light}
                                  />
                                }
                              >
                                Read
                              </Button>
                            </AnimateFadeIn>
                          </ScienceResearchListItemInner>{' '}
                          <ScienceCarouselDesktopHover>
                            <ScienceCarouselDesktopHoverLink
                              href={slide.url}
                              aria-label={`Read Article`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <TextBodyLarge>{slide.title}</TextBodyLarge>
                              <Button
                                as={`span`}
                                variant={buttonVariants.plain}
                                theme={buttonThemes.light}
                                bold={false}
                                href={slide.url}
                                newTab={true}
                                iconRight={
                                  <IconArrowheadRight
                                    title="Read Article"
                                    fill={colors.light}
                                  />
                                }
                              >
                                Read
                              </Button>
                            </ScienceCarouselDesktopHoverLink>
                          </ScienceCarouselDesktopHover>
                        </ScienceCarouselResearchListItem>
                      )),
                    )}
                  </ScienceCarouselResearchList>
                  <ScienceResearchNav>
                    <ScienceResearchNavItem inactive={currentListItem === 0}>
                      <Button
                        variant={buttonVariants.outline}
                        iconLeft={<IconArrowheadLeft />}
                        theme={buttonThemes.light}
                        onClick={() => setPrev()}
                      ></Button>
                    </ScienceResearchNavItem>
                    <ScienceResearchCounter>
                      {currentListItem + 1} of {items.length}
                    </ScienceResearchCounter>
                    <ScienceResearchNavItem
                      inactive={currentListItem + 1 === items.length}
                    >
                      <Button
                        variant={buttonVariants.outline}
                        iconRight={<IconArrowheadRight />}
                        theme={buttonThemes.light}
                        onClick={() => setNext()}
                      ></Button>
                    </ScienceResearchNavItem>
                  </ScienceResearchNav>
                </ScienceCarouselResearchListWrap>
              </ScienceCarouselRightInner>
            </ScienceCarouselRight>
          </GridItem>
        </Grid>
      </Container>
    </ScienceResearch>
  );
});

export default ScienceCarousel;
