import styled from 'styled-components';
import { colors } from '../../styles/vars/colors.style';
import { mq } from '../../styles/vars/media-queries.style';

export const ScienceIntroWrapper = styled.div`
  position: relative;
  display: grid;
  height: 100vh;
  min-height: 62rem;
  place-items: center;
  text-align: center;

  ${({ ethicsHero }) => {
    return ethicsHero === true
      ? `
      &:after {
        background-color: ${colors.humeBlack700};
        bottom: 0;
        content: '';
        height: .1rem;
        left: 2.2rem;
        right:2.2rem;
        position: absolute;

        ${mq.tabletL} {

        left: 50%;
        transform: translateX(-50%);
        width: calc((100vw / 16) * 12);
        }
      }
      `
      : ``;
  }}
`;

export const ScienceIntroMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ ethicsHero }) => {
    return !ethicsHero === true
      ? `
      h1 {
    max-width: 20em;
    z-index: 2;
  }

  p {
    max-width: 27em;
    z-index: 2;
  }
      `
      : `
      h1 {

        ${mq.tabletP}{
        max-width: 80%;}

        ${mq.desk} {
          max-width: 60%;
        }
      }
      `;
  }}

  h1, p {
    margin-left: auto;
    margin-right: auto;
  }

  button {
    cursor: pointer;
  }
`;
