import styled from 'styled-components';
import { clamp } from '../../styles/utils/conversion.style';
import { colors } from '../../styles/vars/colors.style';
import { easings } from '../../styles/vars/easings.style';
import { mq } from '../../styles/vars/media-queries.style';

export const ScienceResearch = styled.div`
  background: ${colors.humeBlack700};
  color: ${colors.light};
  ${clamp('padding-top', 104, 152)}
  ${clamp('padding-bottom', 80, 260)}
  margin-top: -.1rem;
  overflow: hidden;
  position: relative;

  &:before {
    background: ${colors.light};
    content: '';
    top: 0;
    position: absolute;
    height: 0.1rem;
    left: 2.2rem;
    right: 2.2rem;

    ${mq.desk} {
      left: calc(50vw / 4 + 3.2rem);
      right: calc(50vw / 4 + 3.2rem);
    }
  }

  ${mq.tabletL} {
    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }
`;

export const ScienceCarouselLeft = styled.div`
  height: 100%;
  text-align: center;
  margin-left: 20%;
  margin-right: 20%;
  position: relative;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    :first-of-type {
      margin-bottom: 1.1rem;
    }

    :nth-of-type(2) {
      height: 6rem;
    }
  }
`;

export const ScienceCarouselRight = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: center;
  z-index: 3;

  ${mq.tabletL} {
    background: transparent;
    opacity: 1;
    pointer-events: all;
    position: relative;
    left: auto;
    padding-left: auto;
    padding-right: auto;
    right: auto;
    top: auto;
    opacity: 1;
    pointer-events: all;
  }

  ${mq.desk} {
    height: 100%;
    justify-content: center;
    opacity: 1;
    overflow: visible;
    pointer-events: all;
    position: relative;
    left: auto;
    padding-left: auto;
    padding-right: auto;
    right: auto;
    top: auto;
  }

  p {
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    :first-of-type {
      font-weight: 600;
      margin-bottom: 0.6rem;
      text-transform: uppercase;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const ScienceCarouselRightInner = styled.div`
  height: 100%;
  padding-top: 4.5rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
  position: relative;

  ${mq.tabletL} {
    padding-top: 0;
    width: 100%;
    > p {
      width: 80%;
    }
  }

  ${mq.desk} {
    height: auto;
    overflow: visible;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;

    > p {
      width: 50%;
    }
  }
`;

export const ScienceCarouselCounter = styled.span`
  display: none;

  ${mq.tabletL} {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 81%;
  }
`;

export const ScienceCarouselResearchListWrap = styled.div`
  position: relative;
`;

export const ScienceCarouselResearchCounter = styled.li`
  border-radius: 50%;
  border: 1px solid ${colors.light};
  background: ${({ count, current }) =>
    count === current ? colors.light : colors.humeBlack700};
  cursor: pointer;
  display: inline-block;
  height: 1.2rem;
  width: 1.2rem;

  :not(:last-of-type) {
    margin-right: 0.8rem;
  }
`;

export const ScienceCarouselResearchList = styled.ul`
  list-style-type: none;
  position: relative;

  ${({ current }) =>
    `transform: translateX(calc(-100% * ${current} - 5rem * ${current}));`}
  transition: transform .6s ${easings.text.in};

  ${mq.tabletP} {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  ${mq.desk} {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    transform: translateX(0);
    transition: none;
    width: 100%;
  }
`;

export const ScienceCarouselResearchListItem = styled.li`
  border: 0.1rem solid rgba(255, 244, 232, 0.2);
  border-radius: 0.5rem;
  cursor: pointer;
  display: inline-block;
  height: 38.5rem;
  margin-top: 1.8rem;
  padding-bottom: 4.8rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  padding-top: 5.5rem;
  text-align: center;
  ${({ index, current }) =>
    index === current ? `opacity: 1;` : `opacity: .4;`}
  transition: opacity .6s ${easings.text.in};

  ${({ index }) =>
    `transform: translateX(calc(100% * ${index} + 5rem *  ${index}));`}

  ${mq.desk} {
    opacity: 1;
    transform: translateX(0);
    transition: none;
  }

  img {
    margin-bottom: 4.2rem;
    margin-left: auto;
    margin-right: auto;
  }

  :not(:first-of-type) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    ${mq.desk} {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
    }
  }

  p {
    display: inline-block;
    font-weight: 500;

    :first-of-type {
      font-weight: 500;
      line-height: 1.18;
      text-transform: none;
    }
  }

  ${mq.desk} {
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
    width: calc(33.3% - 2rem);

    > p {
      :first-of-type {
        display: none;
      }
    }

    :hover {
      > div {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  > a {
    margin-top: 1.8rem;
  }

  a {
    color: ${colors.light};
    border-bottom: 0;
    font-size: 1.4rem;
    font-weight: 600;
    display: inline-block;
    position: relative;
    text-transform: uppercase;

    ${mq.desk} {
      display: none;
    }
  }
`;

export const ScienceResearchListItemInner = styled.div`
  top: 50%;
  position: relative;
  transform: translateY(-50%);

  .gatsby-image-wrapper {
    width: 50%;
    margin-bottom: 4rem;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq.desk} {
    .gatsby-image-wrapper {
      margin-bottom: 0;
    }

    > div p,
    > a {
      display: none;
    }
  }
`;

export const ScienceCarouselDesktopHoverLink = styled.a`
  display: inline-block;
  height: 100%;
  padding: 20% 10%;
  width: 100%;

  ${mq.deskL} {
    padding: 20%;
  }
`;

export const ScienceCarouselDesktopHover = styled.div`
  display: none;

  ${mq.desk} {
    background: linear-gradient(
      35.26deg,
      #f4cc3d -291.58%,
      #6ae63e -145.88%,
      #35d2f4 -74.73%,
      #f435e1 129.45%
    );
    border-radius: 0.5rem;
    bottom: 0;
    color: ${colors.light};
    display: inline-block;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 0;
    transition: opacity 0.2s ${easings.text.in};

    p {
      display: block;
      font-weight: 500;
      height: auto;
      margin-bottom: 3rem;

      :first-of-type {
        font-weight: 500;
        line-height: 1.18;
        text-transform: none;
      }

      :last-of-type {
        margin-bottom: 3rem;
      }
    }

    span {
      color: ${colors.light};
    }
    a {
      display: block;
      text-align: left;
    }
  }

  ${mq.deskL} {
    p {
      font-size: 2rem;
    }
  }
`;
export const ScienceResearchNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 3.2rem;

  button {
    height: 5.6rem;
    min-width: 0;
    width: 5.6rem;

    span {
      margin: 0;
    }
  }

  ${mq.desk} {
    display: none;
  }
`;
export const ScienceResearchNavItem = styled.div`
  transition: opacity 0.3s ${easings.text.in};
  opacity: ${({ inactive }) => (inactive ? `0.2` : `1`)};
`;

export const ScienceResearchCounter = styled.span`
  min-width: 10rem;
  text-align: center;
`;
